import React, { useState, useEffect, useRef, useMemo  } from 'react';
import { DashboardLayout } from 'layouts';
import { BreadCrumb, ErrorBlock } from 'components';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import styles from "./styles";
import AnalyticsExporter from "./analyticsExporter";
import DashboardCard from 'components/DashboardCard';
import AreaChart from 'components/eCharts/AreaChart';
import BarChart from 'components/eCharts/BarChart';
import PieChart from 'components/eCharts/PieChart';
import FunnelChart from 'components/eCharts/FunnelChart';
import { ButtonDropdown } from 'elements';
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import {BiInfoCircle} from 'react-icons/bi';
import {AiOutlineReload} from 'react-icons/ai';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import UseIntersection from '../../elements/UseIntersection'
import { DateRangePicker } from "mui-daterange-picker";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Button } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import { 
  getCCAnnualSalesTrend, 
  getCCSalesVolume, 
  getCCTopTenColors, 
  getCCTopTenProducts, 
  getCCTopCanSales, 
  getCustomFormulaSales, 
  getCCMachineStat,
  getCCBucketAnalytics, 
  getCCBucketAnalyticsNewShop, 
  getCCBucketAnalyticsVolumeTinted, 
  getCCBucketAnalyticsLocation, 
  getCCLocationBasedSales,
  getCCColorantConsumption,
  getCCTopTenSubProducts,
  getCCTopTenBaseProducts,
  getCCColorantConsumptionBar
} from "../../redux/actions/analytics/analyticsActions";
import {useTranslation} from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import {userRequestPermissionAction} from "../../redux/actions/userActivation/userActivationActions"
import Swal from 'sweetalert2'
import Chip from '@mui/material/Chip'
import SendIcon from '@mui/icons-material/Send';
import { Spinner } from "reactstrap"
import { IconButton } from '@mui/material';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

const useStyles = makeStyles(styles)

const AlertSection = styled('div')(({ theme,height }) => ({
  // position: "-webkit-sticky",
  // position: "sticky",
  // top: "20%",
  width:"100%",
  height: 'auto',
  boxShadow: '0px 2px 4px #00000029',
  backgroundColor: "#e1e1e1",
  borderRadius: "8px",
  marginTop: "3%",
  marginBottom: "6%",
  // opacity: "1",
  fontFamily: "Roboto",
  textAlign: "center",
  padding: "2%",

}))

const FirstSection = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 'auto',
  display:"-webkit-box",
}))

const RootContainer = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"50%",
  display:"-webkit-box",
  minHeight:height,
}))

const MachineStatistics = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"50%",
  display:"-webkit-box",
  minHeight:height,
}))

const WhiteCard = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  minHeight: 500,
  height: 'auto',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 10px #00000029',
  borderRadius: 5,
  padding: '2% 3% 2% 3%',
  marginBottom: '2%'
}))

const DoubleWhiteCard = styled('div')(({ theme,height }) => ({
  position:"relative",
  width:"100%",
  height: 'auto',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 10px #00000029',
  borderRadius: 5,
  padding: '2% 3% 2% 3%',
  marginBottom: '2%'
}))

const ChartButton = styled('span')(({ theme, clickDisable }) => ({
  background: '#c8102e', 
  padding: 10, 
  borderRadius: 5, 
  fontFamily: 'Roboto', 
  cursor: 'pointer',
  color: '#fff',
  fontSize: 12,
  marginLeft: '2%',
  pointerEvents: clickDisable ? "none": 'all',
  "&:hover":{
    background: "#eb314f",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
},
})) 

const ChartButtonWithoutBg = styled('div')(({ theme }) => ({
  background: '#fff', 
  padding: 10, 
  borderRadius: 5, 
  font: 'normal normal normal 14px/15px Roboto',
  border: '1px solid #C8102E',
  cursor: 'pointer', 
  color: '#36333D',
  fontSize: 12,
  textAlign: 'center',
  marginRight: 13,
  "&:hover":{
    background: "#C8102E",
    color: "#fff"
  },
})) 

const ChartButtonActive = styled('div')(({ theme }) => ({
  background: '#C8102E', 
  padding: 10, 
  borderRadius: 5, 
  font: 'normal normal normal 14px/15px Roboto',
  border: '1px solid #C8102E',
  cursor: 'pointer', 
  color: '#fff',
  fontSize: 12,
  textAlign: 'center',
  marginRight: 13
})) 


export default function Dashboard() {
  const formatDate = (datestring, type, dateIncrement) => {
    function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
      var date 
      if(dateIncrement === "sales"){
        var timeStap = new Date(datestring)
        date = new Date(timeStap.setDate(timeStap.getDate() + 1))
      }
      else{
        date = new Date(datestring)
      }
      var month = pad2(date.getMonth()+1);//months (0-11)
      var day = pad2(date.getDate());//day (1-31)
      var year= date.getFullYear();
      var formattedDate
      if(type === "date") {
        formattedDate =  year +"-"+ month+"-"+ day
      }
      else{
        formattedDate =  day +"/"+ month+"/"+ year
        
      }
      return formattedDate; //03/02/2021
}

  const {t} = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  let { code } = useParams();
  const location = useLocation();
  const { 
    ccAnnualSalesTrendSuccess, 
    ccAnnualSalesVolumeSuccess, 
    ccTopTenColorsSuccess, 
    ccTopTenProductsSuccess, 
    ccTopCanSalesSuccess, 
    ccCustomFormulaSalesSuccess, 
    ccMachineStatSuccess, 
    ccBucketAnalyticsSuccess, 
    ccBucketAnalyticsNewShopSuccess, 
    ccBucketAnalyticsVolumeTintedSuccess, 
    ccBucketAnalyticsLocationSuccess,
    exportLoading,
    ccBucketAnalyticsLocationBasedSalesSuccess,
    ccBucketAnalyticsColorantConsumptionSuccess,
    ccBucketAnalyticsLocationLoading,
    ccBucketAnalyticsVolumeTintedLoading,
    ccBucketAnalyticsLoading,
    ccBucketAnalyticsNewShopLoading,
    ccMachineStatLoading,
    ccTopCanSalesLoading,
    ccBucketAnalyticsLocationBasedSalesLoading,
    ccTopTenColorsLoading,
    ccAnnualSalesTrendLoading,
    ccAnnualSalesVolumeLoading,
    ccBucketAnalyticsColorantConsumptionLoading,
    ccCustomFormulaSalesLoading,
    ccTopTenProductsLoading,
    ccAnnualSalesTrendError,
    ccAnnualSalesVolumeError,
    ccTopTenColorsError,
    ccTopTenProductsError,
    ccTopCanSalesError,
    ccCustomFormulaSalesError,
    ccMachineStatError,
    ccBucketAnalyticsError,
    ccBucketAnalyticsNewShopError,
    ccBucketAnalyticsVolumeTintedError,
    ccBucketAnalyticsLocationError,
    ccBucketAnalyticsLocationBasedSalesError,
    ccBucketAnalyticsColorantConsumptionError,
    sendRequestError, 
    sendRequestSuccess, 
    sendRequestLoading,
    ccBucketAnalyticsColorantConsumptionBarLoading,
    ccBucketAnalyticsColorantConsumptionBarSuccess,
    ccBucketAnalyticsColorantConsumptionBarError,
  } = useSelector((state) => state.analytics.analytics);

  const [annualSalesTrendXaxis, setAnnualSalesTrendXaxis] = useState([]);
  const [annualSalesTrendYaxis, setAnnualSalesTrendYaxis] = useState([]);
  const [annualSalesVolumeXaxis, setAnnualSalesVolumeXaxis] = useState([]);
  const [annualsalesLitreOrOrder, setAnnualsalesLitreOrOrder] = useState(true);
  const [topStatisticsLitreOrOrder, setTopStatisticsLitreOrder] = useState(true);
  const [annualSalesVolumeYaxis, setAnnualSalesVolumeYaxis] = useState([]);
  const [topTenColors, setTopTenColors] = useState([
    '#810015', '#FF2C4F', '#76737D', '#FF637D',  '#A8001B', '#FF99A9',  '#C70021', '#f48799',  '#E30025', '#36333D'
  ]);
  const staticColors = [
    '#A2AAAD', '#C8102E', '#36333D', '#982639', '#FF2C4F', '#f48799', '#E30025',  '#FF637D',  '#FFB9C7', '#76737D'
  ];
  const [topTenColorsData, setTopTenColorsData] = useState([]);
  const [topTenProductsYaxis, setTopTenProductsYaxis] = useState([]);
  const [topTenProductDownload, setTopTenProductDownload] = useState([]);
  const [topTenProductsData, setTopTenProductsData] = useState([]);
  const [topTopCanSalesXaxis, setTopTopCanSalesXaxis] = useState([]);
  const [topCanSalesData, setTopCanSalesData] = useState([]);
  const [locationBasedCurrentMonth, setLocationBasedCurrentMonth] = useState([]);
  const [locationBasedPreviousMonth, setLocationBasedPreviousMonth] = useState([]);
  const [locationBasedTopTen, setLocationBasedTopTen] = useState(true);
  const [locationBasedPosData, setLocationBasedPosData] = useState([]);  
  const [customFormulaSalesRegular, setCustomFormulaSalesRegular] = useState(0);
  const [customFormulaTotal, setCustomFormulaTotal] = useState(0);
  const [customFormulaSalesCustom, setCustomFormulaSalesCustom] = useState(0);
  const [machineStatOnline, setMachineStatOnline] = useState(0);
  const [machineStatTotal, setMachineStatTotal] = useState(0);
  const [machineStatOffline, setMachineStatOffline] = useState(0);
  const [machineStatNonOperational, setMachineStatNonOperational] = useState(0);
  // const [machineStatTrueColor, setMachineStatTrueColor] = useState(0);
  // const [machineStatEasyTint, setMachineStatEasyTint] = useState(0);
  // const [machineStatTouchTint, setMachineStatTouchTint] = useState(0);
  const [machineStatNewerVersions, setMachineStatNewerVersions] = useState(0);
  const [machineStatOlderVersions, setMachineStatOlderVersions] = useState(0);
  const [bucketAnalytics, setBucketAnalytics] = useState(null);
  const [bucketAnalyticsSyncDate, setBuckeAnalyticsSyncDate] = useState(null);  
  const [bucketAnalyticsNewShop, setBucketAnalyticsNewShop] = useState(null);
  const [bucketAnalyticsVolumeTinted, setBucketAnalyticsVolumeTinted] = useState(null);
  const [bucketAnalyticsLocation, setBucketAnalyticsLocation] = useState(null);
  const [activeProductBtn, setActiveProductBtn] = useState('product');
  const [staticData, setStaticData] = useState([]);

  const [closeDropdownBtnStatus, setCloseDropdownBtnStatus] = useState(false);

  const [colorantConsumptionXAxis, setColorantConsumptionXAxis] = useState([]);
  const [colorantConsumptionSeries, setColorantConsumptionSeries] = useState([]);

  const [annualSalesTrendSelectedOption, SetAnnualSalesTrendSelectedOption] = useState(t('XdataAnalyticsDetail.ViewGraph'));
  const [topSalesStaticsSelectedOption, SetTopSalesStaticsSelectedOption] = useState();
  const [selectedTopSale, setSelectedTopSale] = useState();
  const [topCanSalesSelectedOption, SetTopCanSalesSelectedOption] = useState();
  const [selectedTopCanSale, setSelectedTopCanSale] = useState();
  const [selectedOptionCloseStatus, setSelectedOptionCloseStatus] = useState(null);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  const [sendBox, setSendBox] = useState(false);

  //Colorant Consumption Bar
  const [colorantConsumptionBarXaxis, setColorantConsumptionBarXaxis] = useState([]);
  const [colorantConsumptionBarData, setColorantConsumptionBarData] = useState([]);
  const [colorantConsumptionBarColor, setColorantConsumptionBarColor] = useState([]);
  const [selectedColorantConsumptionBar, setSelectedColorantConsumptionBar] = useState();
  const [colorantConsumptionBarSelectedOption, SetColorantConsumptionBarSelectedOption] = useState();


  useEffect(() => {
    SetTopSalesStaticsSelectedOption(process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL ? 
      (process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "This Month" ? t('XdataAnalyticsDetail.thismonth') 
    :  process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "Last Month" ? t('XdataAnalyticsDetail.lastmonth')
    :  process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "This Year" ? t('XdataAnalyticsDetail.thisyear') :
    t('XdataAnalyticsDetail.lastyear')) : t('XdataAnalyticsDetail.lastyear'))
    setSelectedTopSale(process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE ? process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE : "lastyear")
    SetTopCanSalesSelectedOption(process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL ? 
      (process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "This Month" ? t('XdataAnalyticsDetail.thismonth') 
    :  process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "Last Month" ? t('XdataAnalyticsDetail.lastmonth')
    :  process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "This Year" ? t('XdataAnalyticsDetail.thisyear') :
    t('XdataAnalyticsDetail.lastyear')) : t('XdataAnalyticsDetail.lastyear'))
    setSelectedTopCanSale(process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE ? process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE : "lastyear")
    SetColorantConsumptionBarSelectedOption(process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL ? 
      (process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "This Month" ? t('XdataAnalyticsDetail.thismonth') 
    :  process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "Last Month" ? t('XdataAnalyticsDetail.lastmonth')
    :  process.env.REACT_APP_DROPDOWN_DEFAULT_LABEL === "This Year" ? t('XdataAnalyticsDetail.thisyear') :
    t('XdataAnalyticsDetail.lastyear')) : t('XdataAnalyticsDetail.lastyear'))
    setSelectedColorantConsumptionBar(process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE ? process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE : "lastyear")
  }, [t])

  const linkData = [ 
    {
        displayName:t('XdataAnalyticsDetail.XData'),
        link:"/x-data"
    },
    {
        displayName:t('XdataAnalyticsDetail.Analytics'),
        link:"/dashboard/xdata/analytics",
        linkClick:true
    },
    {
      displayName:t('XdataAnalyticsDetail.Buckets'),
      link:"/dashboard/xdata/analytics",
      linkClick:true
    },
    {
      displayName: location.state?.name,
      link:"/xdata/analytics"
    }
  ]

  const [openSalesVolume, setOpenSalesVolume] = useState(false);
  const [annualSalesVolumeDownload, setAnnualSalesVolumeDownload] = useState([]);
  const [annualSalesTrendDownload, setAnnualSalesTrendDownload] = useState([]);
  const [topSaleStatisticsDownload, setTopSaleStatisticsDownload] = useState([]);
  const [colorantConsumptionDownload, setColorantConsumptionDownload] = useState([]);
  const [locationBasedDownload, setLocationBasedDownload] = useState([]);
  const [topCanSalesDownload, setTopCanSalesDownload] = useState([]);
  //Colorant Consumption Bar
  const [colorantConsumptionBarDownload, setColorantConsumptionBarDownload] = useState([]);
  const [salesVolumeLitreOrOrder, setSalesVolumeLiterOrOrder] = useState(true);
  // const [salesVolumeStartDate, setSalesVolumeStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [salesVolumeEndDate, setSalesVolumeEndDate] = useState(changeDateFormat(new Date()));
  const [salesVolumeStartDate, setSalesVolumeStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date", "sales"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard", "sales")});
  const [salesVolumeEndDate, setSalesVolumeEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate()), "date", "sales1"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate()), "datestandard", "sales1")});
  const toggleSalesVolume = () => setOpenSalesVolume(!openSalesVolume); 
  function setDateRangeSalesVolume(dateRangeSalesVolume){
    let endDate = changeDateFormat(dateRangeSalesVolume.endDate);
    setSalesVolumeEndDate(endDate);
    let startDate = changeDateFormat(dateRangeSalesVolume.startDate);
    setSalesVolumeStartDate(startDate);

    let payload_sales_volume = {
      type: salesVolumeLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate
    }
    toggleSalesVolume()
    dispatch(getCCSalesVolume(payload_sales_volume)) 
  }

  const [openTopSaleStatistics, setOpenTopSaleStatistics] = useState(false);
  const toggleTopSaleStatistics = () => setOpenTopSaleStatistics(!openTopSaleStatistics); 
  // const [topSaleStatisticsStartDate, setTopSaleStatisticsStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [topSaleStatisticsEndDate, setTopSaleStatisticsEndDate] = useState(changeDateFormat(new Date()));
  const [topSaleStatisticsStartDate, setTopSaleStatisticsStartDate] = useState({formattedDate: '2022-01-01', formattedStandardDate: '01/01/2022'});
  const [topSaleStatisticsEndDate, setTopSaleStatisticsEndDate] = useState({formattedDate: '2022-12-31', formattedStandardDate: '31/12/2022'});
  function setDateRangeTopSaleStatistics(dateRangeTopSaleStatistics){
    
    let endDate = changeDateFormat(dateRangeTopSaleStatistics.endDate);
    setTopSaleStatisticsEndDate(endDate);
    let startDate = changeDateFormat(dateRangeTopSaleStatistics.startDate);
    setTopSaleStatisticsStartDate(startDate);
    toggleTopSaleStatistics()
    setActiveProductBtn('product')
    let payload_top_ten_colors = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate,
      date_type: selectedTopSale
    }
    dispatch(getCCTopTenColors(payload_top_ten_colors))

    let payload_top_ten_products = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate,
      date_type: selectedTopSale
    }
    dispatch(getCCTopTenProducts(payload_top_ten_products))
  }

  const [openColorantConsumption, setOpenColorantConsumption] = useState(false);
  const toggleColorantConsumption = () => setOpenColorantConsumption(!openColorantConsumption); 
  // const [colorantConsumptionStartDate, setColorantConsumptionStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [colorantConsumptionEndDate, setColorantConsumptionEndDate] = useState(changeDateFormat(new Date()));
  const [colorantConsumptionStartDate, setColorantConsumptionStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard")});
  const [colorantConsumptionEndDate, setColorantConsumptionEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate() - 1), "date"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate() - 1), "datestandard")});
  function setDateRangeColorantConsumption(dateRangeColorantConsumption){
    let endDate = changeDateFormat(dateRangeColorantConsumption.endDate);
    setColorantConsumptionEndDate(endDate);
    let startDate = changeDateFormat(dateRangeColorantConsumption.startDate);
    setColorantConsumptionStartDate(startDate);

    let payload_colorant_consumption = {
      code: code,
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate
    }
    toggleColorantConsumption()
    dispatch(getCCColorantConsumption(payload_colorant_consumption))
    
  }

  const [openTopCanSales, setOpenTopCanSales] = useState(false);
  const toggleTopCanSales = () => setOpenTopCanSales(!openTopCanSales); 
  // const [topCanSalesStartDate, setTopCanSalesStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [topCanSalesEndDate, setTopCanSalesEndDate] = useState(changeDateFormat(new Date()));
  const [topCanSalesStartDate, setTopCanSalesStartDate] = useState({formattedDate: '2022-01-01', formattedStandardDate: '01/01/2022'});
  const [topCanSalesEndDate, setTopCanSalesEndDate] = useState({formattedDate: '2022-12-31', formattedStandardDate: '31/12/2022'});
  function setDateRangeTopCanSales(dateRangeTopCanSales){
    let endDate = changeDateFormat(dateRangeTopCanSales.endDate);
    setTopCanSalesEndDate(endDate);
    let startDate = changeDateFormat(dateRangeTopCanSales.startDate);
    setTopCanSalesStartDate(startDate);

    let payload_top_can_sales = {
      code: code,
      start_date: startDate?.formattedDate,
      end_date: endDate?.formattedDate,
      date_type: selectedTopCanSale
    }
    toggleTopCanSales()
    dispatch(getCCTopCanSales(payload_top_can_sales))
  }

  //Colorant Consumption Bar
  const [openColorantConsumptionBar, setOpenColorantConsumptionBar] = useState(false);
  const toggleColorantConsumptionBar = () => setOpenColorantConsumptionBar(!openColorantConsumptionBar); 
  // const [colorantConsumptionBarStartDate, setColorantConsumptionBarStartDate] = useState(changeDateFormat(new Date(new Date().setDate(new Date().getDate() - 7))));
  // const [colorantConsumptionBarEndDate, setColorantConsumptionBarEndDate] = useState(changeDateFormat(new Date()));
  const [colorantConsumptionBarStartDate, setColorantConsumptionBarStartDate] = useState({formattedDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "date"), formattedStandardDate: formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), "datestandard")});
  const [colorantConsumptionBarEndDate, setColorantConsumptionBarEndDate] = useState({formattedDate: formatDate(new Date().setDate(new Date().getDate() - 1), "date"), formattedStandardDate: formatDate(new Date().setDate(new Date().getDate() - 1), "datestandard")});
  function setDateRangeColorantConsumptionBar(dateRangeColorantConsumptionBar){
    let endDate = changeDateFormat(dateRangeColorantConsumptionBar.endDate);
    setColorantConsumptionBarEndDate(endDate);
    let startDate = changeDateFormat(dateRangeColorantConsumptionBar.startDate);
    setColorantConsumptionBarStartDate(startDate);

  let payload_colorant_consumption_bar = {
    code: code,
    start_date: startDate?.formattedDate,
    end_date: endDate?.formattedDate,
    date_type: selectedTopCanSale
  }
  toggleColorantConsumptionBar()
  dispatch(getCCColorantConsumptionBar(payload_colorant_consumption_bar))
}

  function changeDateFormat(date){ 
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedDate = yyyy + '-' + mm + '-' + dd;
    const formattedStandardDate = dd + '/' + mm + '/' + yyyy;
    
    return {
      'formattedDate': formattedDate,
      'formattedStandardDate': formattedStandardDate
    };
  }

 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    
    if(ccAnnualSalesTrendSuccess?.status === 'success'){
      let annualSalesTrendXaxis_ary = []
      let annualSalesTrendYaxis_ary = []
      ccAnnualSalesTrendSuccess.data.result.forEach((data, key) => {
        annualSalesTrendXaxis_ary.push(data?.year)
        annualSalesTrendYaxis_ary.push(data?.yearly_sales)
      })
      let download_array = []
      ccAnnualSalesTrendSuccess.data.result.forEach((i, k) => {
        download_array.push({year :i.year, yearly_sales :i.yearly_sales, January : "", February: "", March: "", April: "", May: "", June: "", July: "", August: "", September: "", October: "", November: "", December: ""})
         i.monthly_sales.forEach((item, key)=> {
            if(item.month === "January"){
              download_array[k].January = item.total
            }
            else if(item.month === "February"){
              download_array[k].February = item.total
            }
            else if(item.month === "March"){
              download_array[k].March = item.total
            }
            else if(item.month === "April"){
              download_array[k].April = item.total
            }
            else if(item.month === "May"){
              download_array[k].May = item.total
            }
            else if(item.month === "June"){
              download_array[k].June = item.total
            }
            else if(item.month === "July"){
              download_array[k].July = item.total
            }
            else if(item.month === "August"){
              download_array[k].August = item.total
            }
            else if(item.month === "September"){
              download_array[k].September = item.total
            }
            else if(item.month === "October"){
              download_array[k].October = item.total
            }
            else if(item.month === "November"){
              download_array[k].November = item.total
            }
            else{
              download_array[k].December = item.total
            }
         })
      })

      setAnnualSalesTrendDownload(download_array)
      setAnnualSalesTrendXaxis(annualSalesTrendXaxis_ary)
      setAnnualSalesTrendYaxis(annualSalesTrendYaxis_ary)
    }    
    else{
      setAnnualSalesTrendDownload([])
      setAnnualSalesTrendXaxis([])
      setAnnualSalesTrendYaxis([])
    }
  }, [ccAnnualSalesTrendSuccess])

  useEffect(() => {  
    if(ccAnnualSalesVolumeSuccess?.status === 'success'){
      let annualSalesVolumeXaxis_ary = []
      let annualSalesVolumeYaxis_ary = []
      ccAnnualSalesVolumeSuccess.data.result.forEach((data, key) => {
        annualSalesVolumeXaxis_ary.push(data?.date)
        if(salesVolumeLitreOrOrder === true) {
          annualSalesVolumeYaxis_ary.push(data?.order)
        }
        else{
          annualSalesVolumeYaxis_ary.push(data?.volume)
        }
      })
      setAnnualSalesVolumeDownload(ccAnnualSalesVolumeSuccess.data.result)
      setAnnualSalesVolumeXaxis(annualSalesVolumeXaxis_ary)
      setAnnualSalesVolumeYaxis(annualSalesVolumeYaxis_ary)
    }
    else{
      setAnnualSalesVolumeDownload([])
      setAnnualSalesVolumeXaxis([])
      setAnnualSalesVolumeYaxis([])
    }    
  }, [ccAnnualSalesVolumeSuccess, salesVolumeLitreOrOrder])

  function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

  useEffect(() => {  
    if(ccBucketAnalyticsSuccess?.status === 'success'){
      
      function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }

      if(ccBucketAnalyticsSuccess.data.result.bucket_info.last_syc){
        var date = new Date(ccBucketAnalyticsSuccess.data.result.bucket_info.last_syc)
        // var month = pad2(date.getMonth()+1);//months (0-11)
        var day = pad2(date.getDate());//day (1-31)
        var year= date.getFullYear();
  
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        
        const timeString12hr = new Date(ccBucketAnalyticsSuccess.data.result.bucket_info.last_syc)
        .toLocaleTimeString([],
            {hour:'2-digit',minute:'2-digit'}
        );
        var formattedDate =  ordinal_suffix_of(day) + " " + monthNames[date.getMonth()] + " "+ year + " " + timeString12hr;
        setBuckeAnalyticsSyncDate(formattedDate)
      }
      else{
        setBuckeAnalyticsSyncDate(null)
      }
      
     
      setBucketAnalytics(ccBucketAnalyticsSuccess.data.result.bucket_info)
      
    }else{
      setBucketAnalytics(null)
      setBuckeAnalyticsSyncDate(null)
    }
    if(ccBucketAnalyticsNewShopSuccess?.status === 'success'){
      setBucketAnalyticsNewShop(ccBucketAnalyticsNewShopSuccess.data.result)
    }  
    if(ccBucketAnalyticsVolumeTintedSuccess?.status === 'success'){
      setBucketAnalyticsVolumeTinted(ccBucketAnalyticsVolumeTintedSuccess.data)
    } 
    if(ccBucketAnalyticsLocationSuccess?.status === 'success'){
      setBucketAnalyticsLocation(ccBucketAnalyticsLocationSuccess.data.result)
    }   
  }, [ccBucketAnalyticsSuccess, 
    ccBucketAnalyticsNewShopSuccess, 
    ccBucketAnalyticsVolumeTintedSuccess,
    ccBucketAnalyticsLocationSuccess])

  useEffect(() => {  
    if(ccTopTenColorsSuccess?.status === 'success'){
      let topTenColors_ary = []
      ccTopTenColorsSuccess.data.result.forEach((data, key) => {
        topTenColors_ary.push(
          // data?.sales_volume
          { value: (key+1)*10, name: data?.color +' : '+data?.sales_volume, itemStyle: {color: topTenColors[key]} }
        )
      })
      setTopSaleStatisticsDownload(ccTopTenColorsSuccess.data.result)
      setTopTenColorsData(topTenColors_ary)
    }
    else{
      setTopSaleStatisticsDownload([])
      setTopTenColorsData([])
    }    
  }, [ccTopTenColorsSuccess, topTenColors])

  useEffect(() => {  
    if(ccTopTenProductsSuccess?.status === 'success'){
      let topTenProducts_ary = []
      let topTenProductsData_ary = []
      ccTopTenProductsSuccess.data.result.slice().reverse().forEach((data, key) => {
        if(activeProductBtn === "product"){
          topTenProducts_ary.push(data?.product)
        }else if(activeProductBtn === "subproduct"){
          topTenProducts_ary.push(data?.subproduct)
        }else if(activeProductBtn === "base"){
          topTenProducts_ary.push(data?.base)
        }

        if(topStatisticsLitreOrOrder === true){
          topTenProductsData_ary.push(data?.orders)
        }
        else{
          topTenProductsData_ary.push(data?.volume)
        }
        
      })
      setTopTenProductDownload(ccTopTenProductsSuccess.data.result)
      setTopTenProductsYaxis(topTenProducts_ary)
      setTopTenProductsData(topTenProductsData_ary)
    } 
    else{
      setTopTenProductDownload([])
      setTopTenProductsYaxis([])
      setTopTenProductsData([])
    }   
  }, [ccTopTenProductsSuccess, activeProductBtn, topStatisticsLitreOrOrder])

  const bucketAnalyticsTopTenProductsref = useRef();
  const bucketAnalyticsTopTenProductsport = UseIntersection(bucketAnalyticsTopTenProductsref, '0px');
  const [isLoadBucketAnalyticsTopTenProducts, setIsLoadBucktAnalyticsTopTenProducts] = useState(false);

  useEffect(() => {  
    setActiveProductBtn('product')
    let payload_top_ten_colors = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: topSaleStatisticsStartDate?.formattedDate,
      end_date: topSaleStatisticsEndDate?.formattedDate,
      date_type: selectedTopSale
    }
    if(isLoadBucketAnalyticsTopTenColors){
      dispatch(getCCTopTenColors(payload_top_ten_colors))
    }
    
    if(isLoadBucketAnalyticsTopTenProducts){
      let payload_top_ten_products = {
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        code: code,
        start_date: topSaleStatisticsStartDate?.formattedDate,
        end_date: topSaleStatisticsEndDate?.formattedDate,
        date_type: selectedTopSale
      }
      dispatch(getCCTopTenProducts(payload_top_ten_products))
    }
  }, [topSalesStaticsSelectedOption])

  useEffect(() => {  
    let payload_top_can_sales = { 
      code: code,
      start_date: topCanSalesStartDate?.formattedDate,
      end_date: topCanSalesEndDate?.formattedDate,
      date_type: selectedTopCanSale
    }
    if(isLoadBucketAnalyticsTopCanSales){
      dispatch(getCCTopCanSales(payload_top_can_sales))
    }
    
  }, [topCanSalesSelectedOption])

  // useEffect(() => {  
  //   let payload_top_can_sales = { 
  //     code: code,
  //     start_date: topCanSalesStartDate?.formattedDate,
  //     end_date: topCanSalesEndDate?.formattedDate,
  //     date_type: selectedTopCanSale
  //   }
  //   if(isLoadBucketAnalyticsTopCanSales){
  //     dispatch(getCCTopCanSales(payload_top_can_sales))
  //   }
    
  // }, [topCanSalesSelectedOption])


  //Colorant Consumption Bar
  useEffect(() => {  
    let payload_colorant_consumption_bar = { 
      code: code,
      start_date: colorantConsumptionBarStartDate?.formattedDate,
      end_date: colorantConsumptionBarEndDate?.formattedDate,
      date_type: selectedColorantConsumptionBar
    }
    if(isLoadBucketAnalyticsColorantConsumptionBar){
      dispatch(getCCColorantConsumptionBar(payload_colorant_consumption_bar))
    }
    
  }, [colorantConsumptionBarSelectedOption])

  // useEffect(() => {  
  //   let payload_colorant_consumption_bar = { 
  //     code: code,
  //     start_date: colorantConsumptionBarStartDate?.formattedDate,
  //     end_date: colorantConsumptionBarEndDate?.formattedDate,
  //     date_type: selectedColorantConsumptionBar
  //   }
  //   if(isLoadBucketAnalyticsColorantConsumptionBar){
  //     dispatch(getCCColorantConsumptionBar(payload_colorant_consumption_bar))
  //   }
    
  // }, [colorantConsumptionBarSelectedOption])

  useEffect(() => {  
    if(ccBucketAnalyticsLocationBasedSalesSuccess?.status === 'success'){
      let currentMonth = []
      let previousMonth = []
      let posData = []
      if(locationBasedTopTen === true){
        ccBucketAnalyticsLocationBasedSalesSuccess.data.result.top_ten.forEach((data, key) => {
          currentMonth.push(data?.first_quarter_sales_cnt)
          previousMonth.push(data?.second_quarter_sales_cnt)
          posData.push(data?.pos)
          setLocationBasedDownload(ccBucketAnalyticsLocationBasedSalesSuccess.data.result.top_ten)
        })
      }
      else{
        ccBucketAnalyticsLocationBasedSalesSuccess.data.result.worst_ten.forEach((data, key) => {
          currentMonth.push(data?.first_quarter_sales_cnt)
          previousMonth.push(data?.second_quarter_sales_cnt)
          posData.push(data?.pos)
          setLocationBasedDownload(ccBucketAnalyticsLocationBasedSalesSuccess.data.result.worst_ten)
        })
      }
      
      
      setLocationBasedCurrentMonth(currentMonth)
      setLocationBasedPreviousMonth(previousMonth)
      setLocationBasedPosData(posData)
    }    
  }, [ccBucketAnalyticsLocationBasedSalesSuccess, locationBasedTopTen])

  const bucketAnalyticsLocationBasedSalesref = useRef();
  const bucketAnalyticsLocationBasedSalesport = UseIntersection(bucketAnalyticsLocationBasedSalesref, '0px');
  const [isLoadBucketAnalyticsLocationBasedSales, setIsLoadBucktAnalyticsLocationBasedSales] = useState(false);

  if (bucketAnalyticsLocationBasedSalesport) {
    if(!isLoadBucketAnalyticsLocationBasedSales){
      let payload_location_based_sales = {
        code: code
      }
      dispatch(getCCLocationBasedSales(payload_location_based_sales))
      setIsLoadBucktAnalyticsLocationBasedSales(true)
    }
  }


  useEffect(() => {  
    if(ccTopCanSalesSuccess?.status === 'success'){
      let topCanSalesXaxis_ary = []
      let topCanSalesData_ary = []
      ccTopCanSalesSuccess.data.result.forEach((data, key) => {
        topCanSalesXaxis_ary.push(data?.can_size)
        topCanSalesData_ary.push(data?.sale_volume)
      })
      setTopCanSalesDownload(ccTopCanSalesSuccess.data.result)
      setTopTopCanSalesXaxis(topCanSalesXaxis_ary)
      setTopCanSalesData(topCanSalesData_ary)
    }    
  }, [ccTopCanSalesSuccess])


//Colorant Consumption Bar
  useEffect(() => {  
    if(ccBucketAnalyticsColorantConsumptionBarSuccess?.status === 'success'){
      let colorantConsumptionBarXaxis_ary = []
      let colorantConsumptionBarData_ary = []
      let colorantConsumptionBarColor_ary = []
      ccBucketAnalyticsColorantConsumptionBarSuccess.data.result.forEach((data, key) => {
        colorantConsumptionBarXaxis_ary.push(data?.colorant)
        colorantConsumptionBarData_ary.push(data?.colorant_qnt_agg)
        colorantConsumptionBarColor_ary.push(data?.hex_color)
      })
      const filteredData = ccBucketAnalyticsColorantConsumptionBarSuccess.data.result.map(({ colorant, colorant_qnt_agg }) => ({
        colorant,
        colorant_qnt_agg
      }));
      setColorantConsumptionBarDownload(filteredData);
      // setColorantConsumptionBarDownload(ccBucketAnalyticsColorantConsumptionBarSuccess.data.result)
      setColorantConsumptionBarXaxis(colorantConsumptionBarXaxis_ary)
      setColorantConsumptionBarData(colorantConsumptionBarData_ary)
      setColorantConsumptionBarColor(colorantConsumptionBarColor_ary)
    }    
  }, [ccBucketAnalyticsColorantConsumptionBarSuccess])

  useEffect(() => {  
    if(ccCustomFormulaSalesSuccess?.status === 'success'){      
      setCustomFormulaSalesRegular(ccCustomFormulaSalesSuccess.data.result.regular)
      setCustomFormulaSalesCustom(ccCustomFormulaSalesSuccess.data.result.custom_formula)
      setCustomFormulaTotal(ccCustomFormulaSalesSuccess.data.result.total)
    }    
  }, [ccCustomFormulaSalesSuccess])

  useEffect(() => {  
    if(ccMachineStatSuccess?.status === 'success'){      
      setMachineStatTotal(ccMachineStatSuccess.data.machine_stat.total)
      setMachineStatOnline(ccMachineStatSuccess.data.machine_stat.operation_statistics.online)
      setMachineStatOffline(ccMachineStatSuccess.data.machine_stat.operation_statistics.offline)
      setMachineStatNonOperational(ccMachineStatSuccess.data.machine_stat.operation_statistics.non_operational)
      // setMachineStatTrueColor(ccMachineStatSuccess.data.machine_stat.type_statistics.TRUEcolor)
      // setMachineStatEasyTint(ccMachineStatSuccess.data.machine_stat.type_statistics.EASYTINT)
      // setMachineStatTouchTint(ccMachineStatSuccess.data.machine_stat.type_statistics.TOUCH_AND_TINT)
      setMachineStatNewerVersions(ccMachineStatSuccess.data.machine_stat.version_statistics.newer_versions)
      setMachineStatOlderVersions(ccMachineStatSuccess.data.machine_stat.version_statistics.older_versions)
      var static_data = []
      ccMachineStatSuccess.data.machine_stat.type_statistics.forEach((data, index) => {
         static_data.push({value: data.count, name: data.pos_type,itemStyle: {color: ""}})
         
      })
      static_data.forEach((data, index) => {
        staticColors.forEach((color, colorIndex) => {
          if(index === colorIndex){
            static_data[index].itemStyle.color = color
          }
         })
      })
      setStaticData(static_data)
    }else{
      setMachineStatTotal(0)
      setMachineStatOnline(0)
      setMachineStatOffline(0)
      setMachineStatNonOperational(0)
      // setMachineStatTrueColor(0)
      // setMachineStatEasyTint(0)
      // setMachineStatTouchTint(0)
      setMachineStatNewerVersions(0)
      setMachineStatOlderVersions(0)
    }  
  }, [ccMachineStatSuccess])
  
  useEffect(() => {  
    if(ccBucketAnalyticsColorantConsumptionSuccess?.status === 'success'){  
      let colorantConsumptionXaxis_ary = []
      let series_object = []
      let colors = []
      let download_array = []
      ccBucketAnalyticsColorantConsumptionSuccess.data.result.forEach((data, key) => {
        colorantConsumptionXaxis_ary.push(data?.date)        
        if(data?.volume){
          colors = Object.keys(data.volume)          
        }
        download_array.push(data.volume)
      })      
      setColorantConsumptionXAxis(colorantConsumptionXaxis_ary)

      if(colors.length){
        colors.forEach((key_v, key_k) => {
          let series_array_data = []
          ccBucketAnalyticsColorantConsumptionSuccess.data.result.forEach((data_v, key_kk) => {            
            series_array_data.push(data_v.volume[''+key_v+''])
          })
          series_object.push({
            name: key_v,
            type: 'line',
            smooth: true,
            data: series_array_data
          })
        })        
      }
      download_array.forEach((i, k) => {
        ccBucketAnalyticsColorantConsumptionSuccess.data.result.forEach((data, key) => {
           if(key=== k){
            download_array[k].Date = data.date
           }
        })
      })
      setColorantConsumptionDownload(download_array)
      setColorantConsumptionSeries(series_object)
    }    
  }, [ccBucketAnalyticsColorantConsumptionSuccess])

  const dataUsage = useMemo(()=>{
    if(bucketAnalytics&&bucketAnalytics?.data_usage){
      let usage = bucketAnalytics?.data_usage
      if((bucketAnalytics?.data_usage/1024) < 1){
        return `${usage.toFixed(4)} MB`
      }
      else {
        let usage_gb=usage/1024
        return `${usage_gb.toFixed(2)} GB`
      }
    }
    else
      return '0 MB'
  },[bucketAnalytics])
  
  const footerClick  = () => {
    // window.open("/dashboard/xdata/analytics/locations",{
    //   state:location.state?.code
    // },'_blank')
    window.open("/dashboard/xdata/analytics/locations/?code=" + location.state?.code, '_blank') 
  }

  const annualSalesTrendSelectOption = (option) => () => {
    SetAnnualSalesTrendSelectedOption(option)
    setSelectedOptionCloseStatus(null)
    setCloseDropdownBtnStatus(true)
  };

  useEffect(() => {
    SetAnnualSalesTrendSelectedOption(t('XdataAnalyticsDetail.ViewGraph'))
    
  }, [t])

  

  const topSalesStaticsSelectOptionChange = (option) => () => {
    if(option === t('XdataAnalyticsDetail.thismonth')){
      setSelectedTopSale("thismonth")
    }
    else if(option === t('XdataAnalyticsDetail.thisyear')){
      setSelectedTopSale("thisyear")
    }
    else if (option === t('XdataAnalyticsDetail.lastmonth')){
      setSelectedTopSale("lastmonth")
    }
    else{
      setSelectedTopSale("lastyear")
    }
    SetTopSalesStaticsSelectedOption(option)
    setSelectedOptionCloseStatus(null)
    setCloseDropdownBtnStatus(true)
  };

  const topCanSalesSelectOptionChange = (option) => () => {
    if(option === t('XdataAnalyticsDetail.thismonth')){
      setSelectedTopCanSale("thismonth")
    }
    else if(option === t('XdataAnalyticsDetail.thisyear')){
      setSelectedTopCanSale("thisyear")
    }
    else if (option === t('XdataAnalyticsDetail.lastmonth')){
      setSelectedTopCanSale("lastmonth")
    }
    else{
      setSelectedTopCanSale("lastyear")
    }
    SetTopCanSalesSelectedOption(option)
    setSelectedOptionCloseStatus(null)
    setCloseDropdownBtnStatus(true)
  };

  //Colorant Consumption Bar
  const colorantConsumptionBarSelectOptionChange = (option) => () => {
    if(option === t('XdataAnalyticsDetail.thismonth')){
      setSelectedColorantConsumptionBar("thismonth")
      }
    else if(option === t('XdataAnalyticsDetail.thisyear')){
      setSelectedColorantConsumptionBar("thisyear")
      }
    else if (option === t('XdataAnalyticsDetail.lastmonth')){
      setSelectedColorantConsumptionBar("lastmonth")
      }
    else{
      setSelectedColorantConsumptionBar("lastyear")
      }
    SetColorantConsumptionBarSelectedOption(option)
    setSelectedOptionCloseStatus(null)
    setCloseDropdownBtnStatus(true)
    };

  
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'
  const fileExtension = '.xlsx';

  const chartButtonTypeChange = (btn) => () => {
    let payload_top_ten_products = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: topSaleStatisticsStartDate?.formattedDate,
      end_date: topSaleStatisticsEndDate?.formattedDate,
      date_type: selectedTopSale
    }
    if(btn === "product"){
      setActiveProductBtn('product')
      dispatch(getCCTopTenProducts(payload_top_ten_products))
    }else if(btn === "subproduct"){
      setActiveProductBtn('subproduct')
      dispatch(getCCTopTenSubProducts(payload_top_ten_products))      
    }else if(btn === "base"){
      setActiveProductBtn('base')
      dispatch(getCCTopTenBaseProducts(payload_top_ten_products))    
    }
  };

  const exportToExcel = async (excellData, filename) => {
    const ws = XLSX.utils.json_to_sheet(excellData);
    const wb = { Sheets: { 'data': ws}, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
  }

  const mouseOverEvent = () => {
    
    if(closeDropdownBtnStatus){
      setCloseDropdownBtnStatus(false)
    }    
  }

 
  let verticalLine = ["<span style='border-left: 1px solid #fff;margin-right: 5px;margin-left: 5px'>  </span>"];
  
  
  const Annualref = useRef();
  const annualViewport = UseIntersection(Annualref, '0px'); // Trigger as soon as the element becomes visible
  const [isLoadAnnualTrend, setIsLoadannualTrend] = useState(false);
  if (annualViewport) {
    if(!isLoadAnnualTrend){
      let payload_annual_sales_trend = {
        type: annualsalesLitreOrOrder === true ? "order": "litre",
        code: code
      }
      dispatch(getCCAnnualSalesTrend(payload_annual_sales_trend)) 
      setIsLoadannualTrend(true)
    }
  }
  useEffect(() => {
    let payload_annual_sales_trend = {
      type: annualsalesLitreOrOrder === true ? "order": "litre",
      code: code
    }
    if(isLoadAnnualTrend){
      dispatch(getCCAnnualSalesTrend(payload_annual_sales_trend)) 
    }
    
  }, [annualsalesLitreOrOrder, code, dispatch, isLoadAnnualTrend])

  const colorantConsumptionref = useRef();
  const colorantConsumptionViewport = UseIntersection(colorantConsumptionref, '0px');
  const [isLoadColorantConsumption, setIsLoadColorantConsumption] = useState(false);

  if (colorantConsumptionViewport) {
    if(!isLoadColorantConsumption){
      let payload_colorant_consumption = {
        code: code,
        start_date: colorantConsumptionStartDate?.formattedDate,
        end_date: colorantConsumptionEndDate?.formattedDate
      }
      dispatch(getCCColorantConsumption(payload_colorant_consumption)) 
      setIsLoadColorantConsumption(true)
    }
  }

  const bucketAnalyticsref = useRef();
  const bucketAnalyticsViewport = UseIntersection(bucketAnalyticsref, '0px');
  const [isLoadBucketAnalytics, setIsLoadBucktAnalytics] = useState(false);

  if (bucketAnalyticsViewport) {
    if(!isLoadBucketAnalytics){
      let payload_bucket_analytics = {
        code: code
      }
      dispatch(getCCBucketAnalytics(payload_bucket_analytics))
      setIsLoadBucktAnalytics(true)
    }
  }

  const bucketAnalyticsNewShopref = useRef();
  const bucketAnalyticsNewShopViewport = UseIntersection(bucketAnalyticsNewShopref, '0px');
  const [isLoadBucketAnalyticsNewShop, setIsLoadBucktAnalyticsNewShop] = useState(false);

  if (bucketAnalyticsNewShopViewport) {
    if(!isLoadBucketAnalyticsNewShop){
      let payload_bucket_analytics_new_shop = {
        code: code
      }
      dispatch(getCCBucketAnalyticsNewShop(payload_bucket_analytics_new_shop))
      setIsLoadBucktAnalyticsNewShop(true)
    }
  }

  const bucketAnalyticsVolumeTintedref = useRef();
  const bucketAnalyticsVolumeTintedViewport = UseIntersection(bucketAnalyticsVolumeTintedref, '0px');
  const [isLoadBucketAnalyticsVolumeTinted, setIsLoadBucktAnalyticsVolumeTinted] = useState(false);

  if (bucketAnalyticsVolumeTintedViewport) {
    if(!isLoadBucketAnalyticsVolumeTinted){
      let payload_bucket_analytics_volume_tinted = {
        code: code
      }
      dispatch(getCCBucketAnalyticsVolumeTinted(payload_bucket_analytics_volume_tinted))
      setIsLoadBucktAnalyticsVolumeTinted(true)
    }
  }

  const bucketAnalyticsLocationref = useRef();
  const bucketAnalyticsLocationViewport = UseIntersection(bucketAnalyticsLocationref, '0px');
  const [isLoadBucketAnalyticsLocation, setIsLoadBucktAnalyticsLocation] = useState(false);

  if (bucketAnalyticsLocationViewport) {
    if(!isLoadBucketAnalyticsLocation){
      let payload_bucket_analytics_location = {
        code: code
      }
      dispatch(getCCBucketAnalyticsLocation(payload_bucket_analytics_location))
      setIsLoadBucktAnalyticsLocation(true)
    }
  }

  const bucketAnalyticsSalesVolumeref = useRef();
  const bucketAnalyticsSalesVolumeViewport = UseIntersection(bucketAnalyticsSalesVolumeref, '0px');
  const [isLoadBucketAnalyticsSalesVolume, setIsLoadBucktAnalyticsSalesVolume] = useState(false);

  if (bucketAnalyticsSalesVolumeViewport) {
    if(!isLoadBucketAnalyticsSalesVolume){
      let payload_sales_volume = {
        type: salesVolumeLitreOrOrder === true ? "order": "litre",
        code: code,
        start_date: salesVolumeStartDate?.formattedDate,
        end_date: salesVolumeEndDate?.formattedDate
      }
      dispatch(getCCSalesVolume(payload_sales_volume))
      setIsLoadBucktAnalyticsSalesVolume(true)
    }
  }

  useEffect(() => {
    let payload_sales_volume = {
      type: salesVolumeLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: salesVolumeStartDate?.formattedDate,
      end_date: salesVolumeEndDate?.formattedDate
    }
    if(isLoadBucketAnalyticsSalesVolume){
      dispatch(getCCSalesVolume(payload_sales_volume))
    }
    
  }, [salesVolumeLitreOrOrder])

  const bucketAnalyticsTopTenColorsref = useRef();
  const bucketAnalyticsTopTenColorsport = UseIntersection(bucketAnalyticsTopTenColorsref, '0px');
  const [isLoadBucketAnalyticsTopTenColors, setIsLoadBucktAnalyticsTopTenVolume] = useState(false);

  if (bucketAnalyticsTopTenColorsport) {
    if(!isLoadBucketAnalyticsTopTenColors){
      let payload_top_ten_colors = {
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        code: code,
        start_date: topSaleStatisticsStartDate?.formattedDate,
        end_date: topSaleStatisticsEndDate?.formattedDate,
        date_type: selectedTopSale
      }
      dispatch(getCCTopTenColors(payload_top_ten_colors))
      setIsLoadBucktAnalyticsTopTenVolume(true)
    }
  }

  useEffect(() => {
    let payload_top_ten_colors = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: topSaleStatisticsStartDate?.formattedDate,
      end_date: topSaleStatisticsEndDate?.formattedDate,
      date_type: selectedTopSale
    }
    if(isLoadBucketAnalyticsTopTenColors){
      dispatch(getCCTopTenColors(payload_top_ten_colors))
    }
    
  }, [topStatisticsLitreOrOrder])

  

  if (bucketAnalyticsTopTenProductsport) {
    if(!isLoadBucketAnalyticsTopTenProducts){
      let payload_top_ten_products = {
        type: topStatisticsLitreOrOrder === true ? "order": "litre",
        code: code,
        start_date: topSaleStatisticsStartDate?.formattedDate,
        end_date: topSaleStatisticsEndDate?.formattedDate,
        date_type: selectedTopSale
      }
      if(activeProductBtn === "product"){
        dispatch(getCCTopTenProducts(payload_top_ten_products))
      }else if(activeProductBtn === "subproduct"){
        dispatch(getCCTopTenSubProducts(payload_top_ten_products))      
      }else if(activeProductBtn === "base"){
        dispatch(getCCTopTenBaseProducts(payload_top_ten_products))    
      }
      setIsLoadBucktAnalyticsTopTenProducts(true)
    }
  }

  useEffect(() => {
    let payload_top_ten_products = {
      type:  topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: topSaleStatisticsStartDate?.formattedDate,
      end_date: topSaleStatisticsEndDate?.formattedDate,
      date_type: selectedTopSale
    }
    if(activeProductBtn === "product"){
      if(isLoadBucketAnalyticsTopTenProducts){
        dispatch(getCCTopTenProducts(payload_top_ten_products))
      }
    }else if(activeProductBtn === "subproduct"){
      dispatch(getCCTopTenSubProducts(payload_top_ten_products))      
    }else if(activeProductBtn === "base"){
      dispatch(getCCTopTenBaseProducts(payload_top_ten_products))    
    }
  }, [topStatisticsLitreOrOrder])

  const bucketAnalyticsTopCanSalesref = useRef();
  const bucketAnalyticsTopCanSalesport = UseIntersection(bucketAnalyticsTopCanSalesref, '0px');
  const [isLoadBucketAnalyticsTopCanSales, setIsLoadBucktAnalyticsTopCanSales] = useState(false);

  if (bucketAnalyticsTopCanSalesport) {
    if(!isLoadBucketAnalyticsTopCanSales){
      let payload_top_can_sales = {
        code: code,
        start_date: topCanSalesStartDate?.formattedDate,
        end_date: topCanSalesEndDate?.formattedDate,
        date_type: selectedTopCanSale
      }
      dispatch(getCCTopCanSales(payload_top_can_sales))
      setIsLoadBucktAnalyticsTopCanSales(true)
    }
  }

  //Colorant Consumption Bar
  const bucketAnalyticsColorantConsumptionBarref = useRef();
  const bucketAnalyticsColorantConsumptionBarport = UseIntersection(bucketAnalyticsColorantConsumptionBarref, '0px');
  const [isLoadBucketAnalyticsColorantConsumptionBar, setIsLoadBucktAnalyticsColorantConsumptionBar] = useState(false);

  if (bucketAnalyticsColorantConsumptionBarport) {
    if(!isLoadBucketAnalyticsColorantConsumptionBar){
      let payload_colorant_consumption_bar = {
        code: code,
        start_date: colorantConsumptionBarStartDate?.formattedDate,
        end_date: colorantConsumptionBarEndDate?.formattedDate,
        date_type: selectedColorantConsumptionBar
      }
      dispatch(getCCTopCanSales(payload_colorant_consumption_bar))
      setIsLoadBucktAnalyticsColorantConsumptionBar(true)
    }
  }

  const bucketAnalyticsCustomFormularef = useRef();
  const bucketAnalyticsCustomFormulaport = UseIntersection(bucketAnalyticsCustomFormularef, '0px');
  const [isLoadBucketAnalyticsCustomFormula, setIsLoadBucktAnalyticsCustomFormula] = useState(false);

  if (bucketAnalyticsCustomFormulaport) {
    if(!isLoadBucketAnalyticsCustomFormula){
      let payload_custom_formula_sales = {
        code: code
      }
      dispatch(getCustomFormulaSales(payload_custom_formula_sales))
      setIsLoadBucktAnalyticsCustomFormula(true)
    }
  }

  const bucketAnalyticsMachineStatref = useRef();
  const bucketAnalyticsMachineStatport = UseIntersection(bucketAnalyticsMachineStatref, '0px');
  const [isLoadBucketAnalyticsMachineStat, setIsLoadBucktAnalyticsMachineStat] = useState(false);

  if (bucketAnalyticsMachineStatport) {
    if(!isLoadBucketAnalyticsMachineStat){
      let payload_machine_stat = {
        code: code
      }
      dispatch(getCCMachineStat(payload_machine_stat))
      setIsLoadBucktAnalyticsMachineStat(true)
    }
  }


  

  const bucketInformationTryClick = () => {
    let payload_bucket_analytics = {
      code: code
    }
    dispatch(getCCBucketAnalytics(payload_bucket_analytics))
  }

  const volumeTintedTryClick = () => {
    let payload_bucket_analytics_volume_tinted = {
      code: code
    }
    dispatch(getCCBucketAnalyticsVolumeTinted(payload_bucket_analytics_volume_tinted))
  }

  const newShopTryClick = () => {
    let payload_bucket_analytics_new_shop = {
      code: code
    }
    dispatch(getCCBucketAnalyticsNewShop(payload_bucket_analytics_new_shop))
  }

  const analyticsTryClick = () => {
    let payload_bucket_analytics_location = {
      code: code
    }
    dispatch(getCCBucketAnalyticsLocation(payload_bucket_analytics_location))
  }

  const machineStatTryClick = () => {
    let payload_machine_stat = {
      code: code
    }
    dispatch(getCCMachineStat(payload_machine_stat))
  }

  const salesVolumeTryClick = () => {
    let payload_sales_volume = {
      type: salesVolumeLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: salesVolumeStartDate?.formattedDate,
      end_date: salesVolumeEndDate?.formattedDate
    }
    dispatch(getCCSalesVolume(payload_sales_volume))
  }

  const annualSalesTrendTryClick = () => {
    let payload_annual_sales_trend = {
      type: annualsalesLitreOrOrder === true ? "order": "litre",
      code: code
    }
    dispatch(getCCAnnualSalesTrend(payload_annual_sales_trend)) 
  }

  const toptenColorsTryClick = () => {
    let payload_top_ten_colors = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: topSaleStatisticsStartDate?.formattedDate,
      end_date: topSaleStatisticsEndDate?.formattedDate,
      date_type: selectedTopSale
    }
    dispatch(getCCTopTenColors(payload_top_ten_colors))
  }

  const topTenProductsTryClick = () => {
    let payload_top_ten_products = {
      type: topStatisticsLitreOrOrder === true ? "order": "litre",
      code: code,
      start_date: topSaleStatisticsStartDate?.formattedDate,
      end_date: topSaleStatisticsEndDate?.formattedDate,
      date_type: selectedTopSale
    }
    if(activeProductBtn === "product"){
      dispatch(getCCTopTenProducts(payload_top_ten_products))
    }else if(activeProductBtn === "subproduct"){
      dispatch(getCCTopTenSubProducts(payload_top_ten_products))      
    }else if(activeProductBtn === "base"){
      dispatch(getCCTopTenBaseProducts(payload_top_ten_products))    
    }
  }

  const colorantConsumptionTryClick = () => {
    let payload_colorant_consumption = {
      code: code,
      start_date: colorantConsumptionStartDate?.formattedDate,
      end_date: colorantConsumptionEndDate?.formattedDate
    }
    dispatch(getCCColorantConsumption(payload_colorant_consumption)) 
  }

  const locationBasedTryClick = () => {
    let payload_location_based_sales = {
      code: code
    }
    dispatch(getCCLocationBasedSales(payload_location_based_sales))
  }

  const topCanTryClick = () => {
    let payload_top_can_sales = {
      code: code,
      start_date: topCanSalesStartDate?.formattedDate,
      end_date: topCanSalesEndDate?.formattedDate,
      date_type: selectedTopCanSale
    }
    dispatch(getCCTopCanSales(payload_top_can_sales))
  }

  //Colorant Consumption Bar
  const colorantConsumptionBarTryClick = () => {
    let payload_colorant_consumption_bar = {
      code: code,
      start_date: colorantConsumptionBarStartDate?.formattedDate,
      end_date: colorantConsumptionBarEndDate?.formattedDate,
      date_type: selectedColorantConsumptionBar
    }
    dispatch(getCCColorantConsumptionBar(payload_colorant_consumption_bar))
  }

  const customFormulaTryClick = () => {
    let payload_custom_formula_sales = {
      code: code
    }
    dispatch(getCustomFormulaSales(payload_custom_formula_sales))
  }

  function sendGraphReq() {
    let values = {
      permissionDatabase: false,
      permissionAnalytics: false,
      permissionThemes: false,
      permissionGraphs: true, 
    }
    if(impersonateUserInfo?.status === "success"){
      let permissionEmail = impersonateUserInfo.data.email
      dispatch(userRequestPermissionAction(values, permissionEmail))
      setSendBox(true)

    }
    else{
      let permissionEmail = user.email
      dispatch(userRequestPermissionAction(values, permissionEmail))
      setSendBox(true)
    }
  }
  useEffect(() => {  
    if(sendRequestSuccess?.status === "success" && sendBox === true){
      Swal.fire({
        title: t('WorkspaceUsers.Success'),
        text: t('WorkspaceUsers.SendSucess'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }
  }, [sendRequestSuccess])

  useEffect(() => {
    if(sendRequestError?.status === "failure" && sendBox === true){
      Swal.fire({
        title: t('WorkspaceUsers.permissionError'),
        text: t('WorkspaceUsers.waitTosend'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }
  }, [sendRequestError])



  const { user } = useSelector((state) => state.auth.login);
  if(impersonateUserInfo?.status === "success"){
    let impersonateUserInfoData = impersonateUserInfo?.data
    if(impersonateUserInfoData.is_workspace_graph === false){
      return (
        <DashboardLayout pageLoading={exportLoading}>
    
          <BreadCrumb linkData={linkData}/>
    
          <AnalyticsExporter code={code}/>

          {impersonateUserInfoData.is_workspace_admin === true ? (
            <AlertSection>
              <a> { sendRequestLoading ? <>{t('XdataAnalyticsDetail.wait')} <CloudSyncIcon style={{color:"#C8102E"}} /></> : t('XdataAnalyticsDetail.noticeWorkspace')} </a>
              <IconButton  
                onClick={sendGraphReq}
              > 
              { sendRequestLoading ? null : <SendIcon style={{color:"#C8102E"}} /> }
              </IconButton>
            </AlertSection>) 
            : 
            (<AlertSection>
              {t('XdataAnalyticsDetail.notice')}
            </AlertSection>
          )}
          <FirstSection style={{filter: "blur(10px)", cursor: "default", pointerEvents: "none"}}>
    
            <RootContainer>
              <div className={classes.cardDiv}>
                <DashboardCard
                    errorStatus = {ccBucketAnalyticsError}
                    tryClick={bucketInformationTryClick}
                    errorTopheight={"translateY(80%)"}
                    observerref={bucketAnalyticsref}
                    title={t('XdataAnalyticsDetail.BucketInformation')}
                    height={200}
                    icon="document"
                    columnInfo3={true}
                    columnInfo3Data= { 
                      {
                        column1Title: t('XdataAnalyticsDetail.BucketID'),
                        column1Desc: code,
                        column2Title: t('XdataAnalyticsDetail.DataUsage'),
                        column2Desc: dataUsage,
                        column3Title: t('XdataAnalyticsDetail.Version'),
                        column3Desc: bucketAnalytics?.major_version + "." + bucketAnalytics?.minor_version,
                      }
                    }
                    footerRightContent = {
                      {
                        title: t('XdataAnalyticsDetail.DataLastSyncedat'),
                        desc: bucketAnalyticsSyncDate
                      }
                    }
                    loading={ccBucketAnalyticsLoading}
                />
                <DashboardCard
                errorStatus = {ccBucketAnalyticsVolumeTintedError}
                tryClick={volumeTintedTryClick}
                errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsVolumeTintedref}
                    title={t('XdataAnalyticsDetail.VolumeOrdersTinted')}
                    height={200}
                    icon="fill"
                    columnInfo2={true}
                    columnInfo2Data= { 
                      {
                        column1Title: t('XdataAnalyticsDetail.Volume'),
                        column1Desc: bucketAnalyticsVolumeTinted ? bucketAnalyticsVolumeTinted?.volume_tinted +  'L' : null ,
                        column2Title: t('XdataAnalyticsDetail.NoofOrders'),
                        column2Desc: bucketAnalyticsVolumeTinted?.orders,
                      }
                    }
                    footerLeftContent = {
                      {
                        desc: t('XdataAnalyticsDetail.Inlast24hours')
                      }
                    }
                    loading={ccBucketAnalyticsVolumeTintedLoading}
                />
              </div>
              <div className={classes.cardDiv}>
                <DashboardCard
                 errorStatus = {ccBucketAnalyticsNewShopError}
                 tryClick={newShopTryClick}
                errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsNewShopref}
                    title={t('XdataAnalyticsDetail.NewShopsOpened')}
                    height={200}
                    icon="shop"
                    footerLeftContent = {
                      {
                        title: bucketAnalyticsNewShop?.new_shops,
                        desc: t('XdataAnalyticsDetail.NewShopsOpenedThisYear')
                      }
                    }
                    loading={ccBucketAnalyticsNewShopLoading}
                />
                <DashboardCard
                tryClick={analyticsTryClick}
                errorStatus = {ccBucketAnalyticsLocationError}
                errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsLocationref}
                    title={t('XdataAnalyticsDetail.Locations')}
                    height={200}
                    icon="location"
                    footerLeftContent = {
                      {
                        title: bucketAnalyticsLocation?.no_of_shops,
                        button: {
                          text: t('XdataAnalyticsDetail.ViewAllLocations')
                        }
                      }
                    }
                    footerClick={footerClick}
                    loading={ccBucketAnalyticsLocationLoading}
                />
              </div>
            </RootContainer> 
    
            <MachineStatistics>
              <DashboardCard
                total={machineStatTotal}
                errorStatus = {ccMachineStatError}
                tryClick={machineStatTryClick}
                errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsMachineStatref}
                title={t('XdataAnalyticsDetail.MachineDeploymentStatistics')}
                width="100%"
                height={430}
                loading={ccMachineStatLoading}
                chart={
                  {
                    DoughnutChart: {
                      tooltip: {
                        trigger: 'item',
                        // position: function (pt) {
                        //   return [pt[0], '10%'];
                        // },
                        extraCssText: "background: #fff;color: #000; border-radius: 5px; font-size: 12px",
                        formatter: function(d) {
                          if(d.data.name === "Non-Operational"){
                            return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted2weeks') + "</div>";
                          }
                          else if(d.data.name === "Offline"){
                            return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted24hrs') + "</div>";
                          }
                          else {
                            return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.tinted24hrs') + "</div>";
                          }
                        }
                           
                      },
                      legend: {
                        bottom: 0,
                        align: 'left',
                        
                      },
                      title: {
                        text: t('XdataAnalyticsDetail.Total')+":" + machineStatTotal,
                        left: 'center',
                        top: 'center',
                     },
                     textStyle: {
                        fontFamily: 'Roboto',
                     },
                      series: [
                          {
                          bottom: 15,
                              stillShowZeroSum: false,
                              name: '',
                              type: 'pie',
                              radius: ['45%', '70%'],
                              avoidLabelOverlap: false,
                              label: {
                              show: false,
                              position: 'center'
                              },
                              emphasis: {
                              label: {
                                  show: false,
                                  fontSize: '18',
                                  fontWeight: 'bold',
                                  // formatter: () => {
                                  //   return "Total:" + machineStatTotal; // Use sum variable here
                                  // },
                              }
                              },
                              labelLine: {
                              show: false
                              },
                            
                              data: [
                                  { 
                                      value: machineStatNonOperational, 
                                      name: t('XdataAnalyticsDetail.NonOperational'), 
                                      itemStyle: { color: '#C8102E' } 
                                  },
                                  { 
                                      value: machineStatOnline, 
                                      name: t('XdataAnalyticsDetail.Online'), 
                                      itemStyle:{ color: '#36333d' } 
                                  },
                                  { 
                                      value: machineStatOffline, 
                                      name: t('XdataAnalyticsDetail.Offline'), 
                                      itemStyle:{ color: '#a2aaad' } 
                                  },
                              ]
                            }
                      ]
                    },
                    NestedPie: {
                      tooltip: {
                        trigger: 'item'
                      },
                      legend: {
                        bottom: -7,
                        align: 'left'
                      },
                      textStyle: {
                        fontFamily: 'Roboto'
                     },
                      series: [
                        
                        {
                          stillShowZeroSum: false,
                          name: '',
                          type: 'pie',
                          radius: ['45%', '70%'],
                          labelLine: {
                            length: 30
                          },
                          label: {
                            show: false,
                          },
                          data: staticData,
                          bottom: 15
                        },
                        {
                          bottom: 15,
                          stillShowZeroSum: false,
                          name: '',
                          type: 'pie',
                          selectedMode: 'single',
                          radius: [0, '25%'],
                          label: {
                            show: false,
                          },
                          labelLine: {
                            show: false
                          },
                          selectedOffset: 0,
                          data: [
                            { value: machineStatNewerVersions, name: t('XdataAnalyticsDetail.NewerVersions'), itemStyle:{ color: '#FF2C4F' } },
                            { value: machineStatOlderVersions, name: t('XdataAnalyticsDetail.OlderVersions'), itemStyle:{ color: '#5A5762' }, selected: true }
                          ]
                        }
                      ]
                    }
                  }
                }
              />
            </MachineStatistics>
    
          </FirstSection>
    
          <div></div>
    
        </DashboardLayout>
        
      )
      }
    else if(impersonateUserInfoData.is_workspace_graph === true){
      return (
        <DashboardLayout pageLoading={exportLoading}>
  
          <BreadCrumb linkData={linkData}/>
  
          
          <AnalyticsExporter code={code}/>
          <FirstSection>
  
            <RootContainer>
              <div className={classes.cardDiv}>
                <DashboardCard
                    errorStatus = {ccBucketAnalyticsError}
                    tryClick={bucketInformationTryClick}
                    errorTopheight={"translateY(80%)"}
                    observerref={bucketAnalyticsref}
                    title={t('XdataAnalyticsDetail.BucketInformation')}
                    height={200}
                    icon="document"
                    columnInfo3={true}
                    columnInfo3Data= { 
                      {
                        column1Title: t('XdataAnalyticsDetail.BucketID'),
                        column1Desc: code,
                        column2Title: t('XdataAnalyticsDetail.DataUsage'),
                        column2Desc: dataUsage,
                        column3Title: t('XdataAnalyticsDetail.Version'),
                        column3Desc: bucketAnalytics?.major_version + "." + bucketAnalytics?.minor_version,
                      }
                    }
                    footerRightContent = {
                      {
                        title: t('XdataAnalyticsDetail.DataLastSyncedat'),
                        desc: bucketAnalyticsSyncDate
                      }
                    }
                    loading={ccBucketAnalyticsLoading}
                />
                <DashboardCard
                errorStatus = {ccBucketAnalyticsVolumeTintedError}
                tryClick={volumeTintedTryClick}
                errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsVolumeTintedref}
                    title={t('XdataAnalyticsDetail.VolumeOrdersTinted')}
                    height={200}
                    icon="fill"
                    columnInfo2={true}
                    columnInfo2Data= { 
                      {
                        column1Title: t('XdataAnalyticsDetail.Volume'),
                        column1Desc: bucketAnalyticsVolumeTinted ? bucketAnalyticsVolumeTinted?.volume_tinted +  'L' : null ,
                        column2Title: t('XdataAnalyticsDetail.NoofOrders'),
                        column2Desc: bucketAnalyticsVolumeTinted?.orders,
                      }
                    }
                    footerLeftContent = {
                      {
                        desc: t('XdataAnalyticsDetail.Inlast24hours')
                      }
                    }
                    loading={ccBucketAnalyticsVolumeTintedLoading}
                />
              </div>
              <div className={classes.cardDiv}>
                <DashboardCard
                errorStatus = {ccBucketAnalyticsNewShopError}
                tryClick={newShopTryClick}
                errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsNewShopref}
                    title={t('XdataAnalyticsDetail.NewShopsOpened')}
                    height={200}
                    icon="shop"
                    footerLeftContent = {
                      {
                        title: bucketAnalyticsNewShop?.new_shops,
                        desc: t('XdataAnalyticsDetail.NewShopsOpenedThisYear')
                      }
                    }
                    loading={ccBucketAnalyticsNewShopLoading}
                />
                <DashboardCard
                tryClick={analyticsTryClick}
                errorStatus = {ccBucketAnalyticsLocationError}
                errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsLocationref}
                    title={t('XdataAnalyticsDetail.Locations')}
                    height={200}
                    icon="location"
                    footerLeftContent = {
                      {
                        title: bucketAnalyticsLocation?.no_of_shops,
                        button: {
                          text: t('XdataAnalyticsDetail.ViewAllLocations')
                        }
                      }
                    }
                    footerClick={footerClick}
                    loading={ccBucketAnalyticsLocationLoading}
                />
              </div>
            </RootContainer> 
  
            <MachineStatistics>
              <DashboardCard
                total={machineStatTotal}
                errorStatus = {ccMachineStatError}
                tryClick={machineStatTryClick}
                errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsMachineStatref}
                title={t('XdataAnalyticsDetail.MachineDeploymentStatistics')}
                width="100%"
                height={430}
                loading={ccMachineStatLoading}
                chart={
                  {
                    DoughnutChart: {
                      tooltip: {
                        trigger: 'item',
                        // position: function (pt) {
                        //   return [pt[0], '10%'];
                        // },
                        extraCssText: "background: #fff;color: #000; border-radius: 5px; font-size: 12px",
                        formatter: function(d) {
                          if(d.data.name === "Non-Operational"){
                            return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted2weeks') + "</div>";
                          }
                          else if(d.data.name === "Offline"){
                            return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted24hrs') + "</div>";
                          }
                          else {
                            return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.tinted24hrs') + "</div>";
                          }
                        }
                          
                      },
                      legend: {
                        bottom: 0,
                        align: 'left',
                        
                      },
                      title: {
                        text: t('XdataAnalyticsDetail.Total')+":" + machineStatTotal,
                        left: 'center',
                        top: 'center',
                    },
                    textStyle: {
                        fontFamily: 'Roboto',
                    },
                      series: [
                          {
                          bottom: 15,
                              stillShowZeroSum: false,
                              name: '',
                              type: 'pie',
                              radius: ['45%', '70%'],
                              avoidLabelOverlap: false,
                              label: {
                              show: false,
                              position: 'center'
                              },
                              emphasis: {
                              label: {
                                  show: false,
                                  fontSize: '18',
                                  fontWeight: 'bold',
                                  // formatter: () => {
                                  //   return "Total:" + machineStatTotal; // Use sum variable here
                                  // },
                              }
                              },
                              labelLine: {
                              show: false
                              },
                            
                              data: [
                                  { 
                                      value: machineStatNonOperational, 
                                      name: t('XdataAnalyticsDetail.NonOperational'), 
                                      itemStyle: { color: '#C8102E' } 
                                  },
                                  { 
                                      value: machineStatOnline, 
                                      name: t('XdataAnalyticsDetail.Online'), 
                                      itemStyle:{ color: '#36333d' } 
                                  },
                                  { 
                                      value: machineStatOffline, 
                                      name: t('XdataAnalyticsDetail.Offline'), 
                                      itemStyle:{ color: '#a2aaad' } 
                                  },
                              ]
                            }
                      ]
                    },
                    NestedPie: {
                      tooltip: {
                        trigger: 'item'
                      },
                      legend: {
                        bottom: -7,
                        align: 'left'
                      },
                      textStyle: {
                        fontFamily: 'Roboto'
                    },
                      series: [
                        
                        {
                          stillShowZeroSum: false,
                          name: '',
                          type: 'pie',
                          radius: ['45%', '70%'],
                          labelLine: {
                            length: 30
                          },
                          label: {
                            show: false,
                          },
                          data: staticData,
                          bottom: 15
                        },
                        {
                          bottom: 15,
                          stillShowZeroSum: false,
                          name: '',
                          type: 'pie',
                          selectedMode: 'single',
                          radius: [0, '25%'],
                          label: {
                            show: false,
                          },
                          labelLine: {
                            show: false
                          },
                          selectedOffset: 0,
                          data: [
                            { value: machineStatNewerVersions, name: t('XdataAnalyticsDetail.NewerVersions'), itemStyle:{ color: '#b5b5b5' } },
                            { value: machineStatOlderVersions, name: t('XdataAnalyticsDetail.OlderVersions'), itemStyle:{ color: '#5A5762' }, selected: true }
                          ]
                        }
                      ]
                    }
                  }
                }
              />
            </MachineStatistics>
  
          </FirstSection>
  
          <WhiteCard ref={bucketAnalyticsSalesVolumeref}>
            <div className={classes.datechartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.SalesVolume')}</h2>
                <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                  <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                      <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={salesVolumeLitreOrOrder === true} onChange={(event) => setSalesVolumeLiterOrOrder(true)}  style ={{color: "#C8102E"}} />} 
                            label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                        <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={salesVolumeLitreOrOrder === false} onChange={(event) => setSalesVolumeLiterOrOrder(false)}  style ={{color: "#C8102E"}} />} 
                        label={t('XdataAnalyticsDetail.LitersSold')}/>
                  </div>
                  <ChartButton onClick={toggleSalesVolume}  style={{height: 32,paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                    <CalendarIcon className={classes.CalendarIcon}/> {salesVolumeStartDate.formattedStandardDate} - {salesVolumeEndDate.formattedStandardDate}
                  </ChartButton>    
                  <Tooltip title={t('XdataAnalyticsDetail.download')}>      
                    <ChartButton  clickDisable={annualSalesVolumeXaxis.length === 0 ? true: false} onClick={() => exportToExcel(annualSalesVolumeDownload, "Sales Volume")} style={{height: 32,paddingTop: 5, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                      <DownloadIcon className={classes.DownloadIcon}/>
                    </ChartButton>
                  </Tooltip>    
                </div>                        
            </div>
            <DateRangePicker
              maxDate={new Date(new Date().setDate(new Date().getDate()))}
              minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
              definedRanges={["Last Month", "This Year"]}
              open={openSalesVolume}
              toggle={toggleSalesVolume}
              onChange={(range) => setDateRangeSalesVolume(range)}
              wrapperClassName={classes.wrapperClassNameSalesVolume}
            />
            {
              ccAnnualSalesVolumeLoading  ? 
                    <div className={classes.loaderDiv}>
                        <CircularProgress color="secondary" sx={{color:'#C8102E'}}/>
                    </div> 
                  :
                ccAnnualSalesVolumeError === 0 ? 
                  <ErrorBlock btnClick={salesVolumeTryClick} />
                    :
              annualSalesVolumeXaxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <>
              <AreaChart chartInfo={
                {
                  tooltip: {
                    trigger: 'axis',
                    // position: function (pt) {
                    //   return [pt[0], '10%'];
                    // }
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: annualSalesVolumeXaxis,
                    axisLabel: {
                      showMaxLabel: true
                    }             
                  },
                  yAxis: {
                    type: 'value',
                    axisLabel: {
                      formatter: '{value}', // {value} L
                      margin: 10,
                    }
                  },
                  dataZoom: [
                    {
                      type: 'inside',
                      start: 0,
                      end: 100
                    },
                    {
                      start: 0,
                      end: 100,
                      borderColor: "#FFB1A8",
                      backgroundColor: "#FFB1A8",
                      height: 30,
                      moveHandleStyle:{
                        color: "#C8102E",
                        opacity: 1
                      },
                      dataBackground:{
                          lineStyle: {
                            color: '#C8102E',
                            width: 0.5
                          },
                          areaStyle: {
                            color: '#C8102E',
                            width: 0.5
                          }
                      },
                      selectedDataBackground:{
                        lineStyle: {
                          color: '#C8102E',
                          width: 0.5
                        },
                        areaStyle: {
                          color: '#C8102E',
                          width: 0.5
                        }
                    },
                      emphasis:{
                        moveHandleStyle: {
                          color: "#C8102E",
                          opacity: 0.5
                        }
                      },
                      textStyle: {
                        fontFamily: 'Roboto',
                        color: '#000'
                      }
                    }
                  ],
                  textStyle: {
                    fontFamily: 'Roboto',
                },
                  series: [
                    {
                      data: annualSalesVolumeYaxis,
                      type: 'line',
                      smooth: true,
                      itemStyle: {color: '#C8102E'},
                      lineStyle: {color: '#C8102E'},
                      areaStyle: {color: '#FFB1A8'},
                    }
                  ]
                }
              }/>
              {
                salesVolumeLitreOrOrder === false ? 
                  <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                    <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                    <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
                </div>:
                null
              }
              
              </>
            }
            
          </WhiteCard>
  
          <WhiteCard ref={Annualref}>
            <div className={classes.datechartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.AnnualSalesTrend')}</h2>
                <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                  <span className={classes.chartRightChild}>
                    <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                        <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={annualsalesLitreOrOrder === true} onChange={(event) => setAnnualsalesLitreOrOrder(true)}  style ={{color: "#C8102E"}} />} 
                              label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                          <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={annualsalesLitreOrOrder === false} onChange={(event) => setAnnualsalesLitreOrOrder(false)}  style ={{color: "#C8102E"}} />} 
                          label={t('XdataAnalyticsDetail.LitersSold')}/>
                    </div>
                  </span>
                  <ButtonDropdown 
                    label={t('XdataAnalyticsDetail.ViewGraph')}
                    options={[t('XdataAnalyticsDetail.ViewGraph'), t('XdataAnalyticsDetail.ViewTable')]}
                    selectedOption={annualSalesTrendSelectedOption}
                    annualSalesTrendSelectOption={annualSalesTrendSelectOption}
                    selectedOptionClose={selectedOptionCloseStatus}
                    closeDropdownBtn={closeDropdownBtnStatus}     
                    mouseOverEvent={mouseOverEvent}  
                  />
                  <Tooltip title={t('XdataAnalyticsDetail.download')}> 
                  <ChartButton clickDisable={annualSalesTrendXaxis.length === 0 ? true: false} onClick={() => exportToExcel(annualSalesTrendDownload, "Annual Sales Trend")} style={{height: 32,paddingTop: 5, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                  </Tooltip>
                </div>            
            </div>
            {annualSalesTrendSelectedOption=== t('XdataAnalyticsDetail.ViewGraph') ? 
            ccAnnualSalesTrendLoading ? 
            <div className={classes.loaderDiv}>
                      <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                  </div>:
                  ccAnnualSalesTrendError === 0 ? 
                  <ErrorBlock btnClick={annualSalesTrendTryClick}  />
                    :
              annualSalesTrendXaxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <>
              <AreaChart chartInfo={
              {
                tooltip: {
                  trigger: 'axis',
                  // position: function (pt) {
                  //   return [pt[0], '10%'];
                  // }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: annualSalesTrendXaxis,
                  axisLabel: {
                    showMaxLabel: true
                  }           
                },
                yAxis: {
                  type: 'value',
                  axisLabel: {
                    formatter: '{value}', // {value} L
                    margin: 10,
                  }
                },
                textStyle: {
                  fontFamily: 'Roboto',
              },
                series: [
                  {
                    data: annualSalesTrendYaxis,
                    type: 'line',
                    smooth: true,
                    itemStyle: {color: '#C8102E'},
                    lineStyle: {color: '#C8102E'},
                  }
                ]
              }
            }/>
            {
              annualsalesLitreOrOrder === false ? 
                <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
              </div>:
              null
            }
            </>
            :
            annualSalesTrendXaxis.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
              :
              <>
            <Table sx={{ marginTop: 5 }} size="small">
            <TableHead>
              <TableRow sx={{ 'td, th': { border: '1px solid #A7A7A7', background:'#DDDDDD', fontWeight: 'bold' } }}>
                <TableCell></TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">{row}</TableCell>   
                ))}        
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                    {t('XdataAnalyticsDetail.Jan')}
                  </TableCell>
                  
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "January") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
                  
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Feb')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "February") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Mar')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "March") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Apr')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "April") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.May')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "May") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Jun')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "June") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Jul')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "July") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Aug')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "August") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Sep')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "September") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Oct')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "October") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Nov')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "November") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Dec')}
                  </TableCell>
                  {annualSalesTrendXaxis.map((row) => (
                    <TableCell align="center">
                    {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                      (row === data.year)&&
                        data.monthly_sales.map((month_v, month_k)=> (                      
                            (month_v.month === "December") ? month_v.total : ''                       
                        ))                  
                    ))}
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow
                  sx={{ 'td, th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
                >
                  <TableCell align="center" component="th">
                    {t('XdataAnalyticsDetail.Total')}
                  </TableCell>
                  {annualSalesTrendYaxis.map((rowTotal) => (
                    <TableCell align="center">{rowTotal}</TableCell>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
          {
              annualsalesLitreOrOrder === false ? 
                <div style={{display: 'flex', marginLeft: 20, marginTop: 10}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
              </div>:
              null
            }
            </>
          }
          
          </WhiteCard>
  
          <DoubleWhiteCard>
            <div className={classes.datechartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.TopSalesStatistics')}</h2>
                <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                  <span className={classes.chartRightChild}>
                    <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                        <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={topStatisticsLitreOrOrder === true} onChange={(event) => setTopStatisticsLitreOrder(true)}  style ={{color: "#C8102E"}} />} 
                              label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                          <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={topStatisticsLitreOrOrder === false} onChange={(event) => setTopStatisticsLitreOrder(false)}  style ={{color: "#C8102E"}} />} 
                          label={t('XdataAnalyticsDetail.LitersSold')}/>
                    </div>
                  </span>
                  {/* <ChartButton clickDisable onClick={toggleTopSaleStatistics} style={{height: 32,paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                    <CalendarIcon className={classes.CalendarIcon}/> {topSaleStatisticsStartDate.formattedStandardDate} - {topSaleStatisticsEndDate.formattedStandardDate}
                  </ChartButton>               */}
                  <ButtonDropdown 
                    label={t('XdataAnalyticsDetail.lastyear')}
                    options={[t('XdataAnalyticsDetail.lastyear'),t('XdataAnalyticsDetail.lastmonth'),t('XdataAnalyticsDetail.thisyear'), t('XdataAnalyticsDetail.thismonth')]}
                    selectedOption={topSalesStaticsSelectedOption}
                    annualSalesTrendSelectOption={topSalesStaticsSelectOptionChange}
                    selectedOptionClose={selectedOptionCloseStatus}
                    closeDropdownBtn={closeDropdownBtnStatus}     
                    mouseOverEvent={mouseOverEvent}  
                  />
                </div>            
            </div>
            <DateRangePicker
              // maxDate={new Date()}
              // minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
              open={openTopSaleStatistics}
              toggle={toggleTopSaleStatistics}
              onChange={(range) => setDateRangeTopSaleStatistics(range)}
              wrapperClassName={classes.wrapperClassNameSalesVolume}
            />
            <div className={classes.DoubleWhiteCardChild} ref={bucketAnalyticsTopTenColorsref}>
              <div className={classes.chartHeader}>
                  <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.Colors')}</h2>
                  <div className={classes.chartRightDiv}>
                  <Tooltip title={t('XdataAnalyticsDetail.download')}> 
                    <ChartButton clickDisable={topTenColorsData.length === 0 ? true: false} onClick={() => exportToExcel(topSaleStatisticsDownload, "Top Sales Statistics")}>
                      <DownloadIcon className={classes.DownloadIcon}/>
                    </ChartButton>
                    </Tooltip>
                  </div>            
              </div>
              {
              ccTopTenColorsLoading ? 
              <div className={classes.loaderDiv}>
                        <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                    </div>:
              ccTopTenColorsError === 0 ? 
              <ErrorBlock btnClick={toptenColorsTryClick} />
                :
              topTenColorsData.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <FunnelChart chartInfo={{
                tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b}'
                },
                legend: {
                  right: 50,
                  orient: 'vertical',
                  // padding: 50,
                  // itemGap: 10,
                },
                textStyle: {
                  fontFamily: 'Roboto',
              },
                series: [
                  {
                    left: '0%',
                    name: t('XdataAnalyticsDetail.Colors'),
                    type: 'funnel',
                    sort: 'ascending',
                    width: '50%',
                    gap: 4,
                    label: {
                      show: true,
                      color: 'black',
                    },
                    labelLine: {
                      length: 30,
                      lineStyle: {
                        width: 1,
                        type: 'solid'
                      }
                    },
                    itemStyle: {
                      borderColor: '#fff',
                      borderWidth: 1
                    },
                    emphasis: {
                      label: {
                        fontSize: 20
                      }
                    },
                    data: topTenColorsData
                  }
                ]
              }}/>
            }
            </div>
            <div className={classes.DoubleWhiteCardChild} ref={bucketAnalyticsTopTenProductsref}>
              <div className={classes.chartHeader}>
                  <div className={classes.chartLeftBtns}>
                    {activeProductBtn === "product" ? (<ChartButtonActive>{t('XdataAnalyticsDetail.Product')}</ChartButtonActive>) : (<ChartButtonWithoutBg onClick={chartButtonTypeChange('product')}>{t('XdataAnalyticsDetail.Product')}</ChartButtonWithoutBg>)}
                    {activeProductBtn === "subproduct" ? (<ChartButtonActive>{t('XdataAnalyticsDetail.Subproduct')}</ChartButtonActive>) : (<ChartButtonWithoutBg onClick={chartButtonTypeChange('subproduct')}>{t('XdataAnalyticsDetail.Subproduct')}</ChartButtonWithoutBg>)}
                    {activeProductBtn === "base" ? (<ChartButtonActive>{t('XdataAnalyticsDetail.Base')}</ChartButtonActive>) : (<ChartButtonWithoutBg onClick={chartButtonTypeChange('base')}>{t('XdataAnalyticsDetail.Base')}</ChartButtonWithoutBg>)}                
                  </div>
                  <div className={classes.chartRightDiv}>
                  <Tooltip title={t('XdataAnalyticsDetail.download')}> 
                    <ChartButton clickDisable={topTenProductsYaxis.length === 0 ? true: false} onClick={() =>{activeProductBtn === "product"? exportToExcel(topTenProductDownload, "Top Products") :activeProductBtn === "subproduct"? exportToExcel(topTenProductDownload, "Top SubProducts"): exportToExcel(topTenProductDownload, "Top Base")}}>
                      <DownloadIcon className={classes.DownloadIcon}/>
                    </ChartButton>
                    </Tooltip>
                  </div>            
              </div>
              {
                ccTopTenProductsLoading ? 
                <div className={classes.loaderDiv}>
                          <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                      </div>:
                      ccTopTenProductsError === 0 ? 
                      <ErrorBlock btnClick={topTenProductsTryClick} />
                        :
              topTenProductsYaxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <BarChart chartInfo={{
                tooltip: {
                  trigger: 'axis',
                  // position: function (pt) {
                  //   return [pt[0], '10%'];
                  // },
                  extraCssText: "background: #36333D;color: #FFFFFF; border-radius: 5px; font-size: 12px",
                  formatter: function(d) {
                    return d[0].name  + verticalLine + d[0].data;
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '5%',
                  containLabel: true
                },
              
                xAxis: {
                  type: 'value',
                },
                yAxis: {
                  type: 'category',
                  data: topTenProductsYaxis
                },
                textStyle: {
                  fontFamily: 'Roboto',
              },
                series: [
                  {
                    data: topTenProductsData,
                    type: 'bar',
                    itemStyle: {color: '#FF8397'},
                    barWidth:25
                  }
                ]
              }} />
            }
            </div>
            {
                topStatisticsLitreOrOrder === false ? 
                  <div style={{display: 'flex', marginTop: 10}}>
                    <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                    <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
                </div>:
                null
              }
          </DoubleWhiteCard>
  
          <WhiteCard ref={colorantConsumptionref}>
            <div className={classes.datechartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.ColorantConsumption')}</h2>
                <div className={classes.chartRightDiv}>
                <ChartButton  onClick={toggleColorantConsumption}>
                  <CalendarIcon className={classes.CalendarIcon}/> {colorantConsumptionStartDate.formattedStandardDate} - {colorantConsumptionEndDate.formattedStandardDate}
                </ChartButton> 
                <Tooltip title={t('XdataAnalyticsDetail.download')}>             
                  <ChartButton clickDisable={colorantConsumptionXAxis.length === 0 ? true: false} onClick={() => exportToExcel(colorantConsumptionDownload, "Colorant Consumption")}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                  </Tooltip>
                </div>            
            </div>
            <DateRangePicker
              maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
              minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
              definedRanges={["Last Month", "This Year"]}
              open={openColorantConsumption}
              toggle={toggleColorantConsumption}
              onChange={(range) => setDateRangeColorantConsumption(range)}
              wrapperClassName={classes.wrapperClassNameSalesVolume}
            />
            {
              ccBucketAnalyticsColorantConsumptionLoading ? 
              <div className={classes.loaderDiv}>
                  <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
              </div>:
              ccBucketAnalyticsColorantConsumptionError === 0 ? 
              <ErrorBlock btnClick={colorantConsumptionTryClick} />
                :
              colorantConsumptionXAxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <>
            <AreaChart chartInfo={
              {
                tooltip: {
                  trigger: 'axis',
                  // position: function (pt) {
                  //   return [pt[0], '10%'];
                  // }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '13%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: colorantConsumptionXAxis,
                  axisLabel: {
                    showMaxLabel: true
                  }             
                },
                yAxis: {
                  type: 'value',
                  axisLabel: {
                    formatter: '{value}', // {value} L
                    margin: 10,
                  }
                },
                dataZoom: [
                  {
                    type: 'inside',
                    start: 0,
                    end: 100
                  },
                  {
                    start: 0,
                    end: 100,
                    borderColor: "#FFB1A8",
                    backgroundColor: "#FFB1A8",
                    height: 30,
                    moveHandleStyle:{
                      color: "#C8102E",
                      opacity: 1
                    },
                    dataBackground:{
                        lineStyle: {
                          color: '#C8102E',
                          width: 0.5
                        },
                        areaStyle: {
                          color: '#C8102E',
                          width: 0.5
                        }
                    },
                    selectedDataBackground:{
                      lineStyle: {
                        color: '#C8102E',
                        width: 0.5
                      },
                      areaStyle: {
                        color: '#C8102E',
                        width: 0.5
                      }
                  },
                    emphasis:{
                      moveHandleStyle: {
                        color: "#C8102E",
                        opacity: 0.5
                      }
                    },
                    textStyle: {
                      color: '#000'
                    }
                  }
                ],
                series: colorantConsumptionSeries
              }
            }/>
            <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>
            </>
          }
          </WhiteCard>

          <WhiteCard ref={bucketAnalyticsColorantConsumptionBarref}>
            
            {/* <div ref={bucketAnalyticsColorantConsumptionBarref}> */}
                <div className={classes.chartHeader}>
                  <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.ColorantConsumptionBarChart')}</h2>
                  <div className={classes.chartRightDiv}>     
                    {/* <ChartButton clickDisable onClick={toggleTopCanSales}>
                      <CalendarIcon className={classes.CalendarIcon}/> {topCanSalesStartDate.formattedStandardDate} - {topCanSalesEndDate.formattedStandardDate}
                    </ChartButton>   */}
                    <ButtonDropdown 
                      label={"Last Year"}
                      options={[t('XdataAnalyticsDetail.lastyear'),t('XdataAnalyticsDetail.lastmonth'),t('XdataAnalyticsDetail.thisyear'), t('XdataAnalyticsDetail.thismonth')]}
                      selectedOption={colorantConsumptionBarSelectedOption}
                      annualSalesTrendSelectOption={colorantConsumptionBarSelectOptionChange}
                      selectedOptionClose={selectedOptionCloseStatus}
                      closeDropdownBtn={closeDropdownBtnStatus}     
                      mouseOverEvent={mouseOverEvent}  
                    />
                    <Tooltip title={t('XdataAnalyticsDetail.download')}>
                    <ChartButton clickDisable={colorantConsumptionBarXaxis.length === 0 ? true: false} onClick={() => exportToExcel(colorantConsumptionBarDownload, "Top Can Sales")}>
                      <DownloadIcon className={classes.DownloadIcon}/>
                    </ChartButton>
                    </Tooltip>
                  </div>            
                </div>
                <DateRangePicker
                // maxDate={new Date()}
                // minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                open={openColorantConsumptionBar}
                toggle={toggleColorantConsumptionBar}
                onChange={(range) => setDateRangeColorantConsumptionBar(range)}
                wrapperClassName={classes.wrapperClassNameSalesVolume}
              />
              {
                ccBucketAnalyticsColorantConsumptionBarLoading ? 
                <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccBucketAnalyticsColorantConsumptionBarError === 0 ? 
                <ErrorBlock btnClick={colorantConsumptionBarTryClick} />
                  :
              colorantConsumptionBarXaxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <>
              <BarChart chartInfo={{
                  tooltip: {
                    trigger: 'axis',
                    position: function (pt) {
                      return [pt[0], '10%'];
                    }
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '6%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'category',
                    data: colorantConsumptionBarXaxis,
                    axisLabel: {
                      interval: 0,
                      rotate: 30 //If the label names are too long you can manage this by rotating the label.
                    },
                    axisTick: {
                      alignWithLabel: true
                    }
                  },
                  yAxis: {
                    type: 'value',
                  },
                  textStyle: {
                    fontFamily: 'Roboto',
                },
                  series: [                
                      {                  
                        data: colorantConsumptionBarData,
                        type: 'bar',
                        itemStyle: {borderRadius: 5,
                          color: function (params) {
                          const color = colorantConsumptionBarColor[params.dataIndex % colorantConsumptionBarColor.length];
                          return color;
                        },
                        barWidth: 13,
                        borderColor: '#000000', // Set the border color to black
                        borderWidth: 0.5 // Set the border width
                      }
                    }
                    ]
                  
              }}/>
              <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>
            </>
            }
            {/* </div> */}
            
        </WhiteCard>
  
          <WhiteCard ref={bucketAnalyticsLocationBasedSalesref}>
            <div className={classes.datechartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.LocationBasedSales')}</h2>
                <div className={classes.datechartRightDiv}  style={{display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%'}}>
  
                <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                        <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={locationBasedTopTen === true} onChange={(event) => setLocationBasedTopTen(true)}  style ={{color: "#C8102E"}} />} 
                              label={t('XdataAnalyticsDetail.TopTen')}/>
                          <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={locationBasedTopTen === false} onChange={(event) => setLocationBasedTopTen(false)}  style ={{color: "#C8102E"}} />} 
                          label={t('XdataAnalyticsDetail.WorstTen')}/>
                    </div>
                  <Tooltip title={t('XdataAnalyticsDetail.reload')}>
                    <ChartButton  onClick={locationBasedTryClick} style={{marginRight: 5}}>
                      <AiOutlineReload className={classes.DownloadIcon}/>
                    </ChartButton>  
                  </Tooltip>
                  
                  <Tooltip title={t('XdataAnalyticsDetail.download')}>
                  <ChartButton clickDisable={locationBasedPosData.length === 0 ? true: false} style={{padding: 8}} onClick={() => exportToExcel(locationBasedDownload, "Location Based Sales")}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                  </Tooltip>
                </div>
            </div>
            {
              ccBucketAnalyticsLocationBasedSalesLoading ? 
              <div className={classes.loaderDiv}>
                  <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
              </div>:
              ccBucketAnalyticsLocationBasedSalesError === 0 ? 
              <ErrorBlock btnClick ={locationBasedTryClick} />
                :
              locationBasedPosData.length === 0 ? 
              <>
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              <div style={{position: 'absolute', bottom: 26, left: 40, display: 'flex'}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.prevandcurrentquarter')}</p>
                </div>
              </>
              :
              <>
            <BarChart height={430} chartInfo={{
                  tooltip: {
                    trigger: 'axis',
                    position: function (pt) {
                      return [pt[0], '10%'];
                    }
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '9%',
                    containLabel: true
                  },
                  legend: {
                    bottom: -5,
                    right: 0,
                    align: 'left',
                  },
                  xAxis: {
                    type: 'category',
                    data: locationBasedPosData,
                    axisLabel: {
                      width: locationBasedPosData.length > 5 ?  80: 100, 
                      overflow: 'truncate',
                      interval: 0,
                    },
                    axisTick:{
                      alignWithLabel: true
                    }
                  },
                  yAxis: {
                    type: 'value'
                  },
                  textStyle: {
                    fontFamily: 'Roboto',
                },
                  series: [
                    {
                      name: t('XdataAnalyticsDetail.prevQuarter'),
                      data: locationBasedPreviousMonth,
                      type: 'bar',
                      itemStyle: {color: '#FFA1B0', borderRadius: 5},
                      barWidth:13
                    },
                    {
                      name: t('XdataAnalyticsDetail.currentQuarter'),
                      data: locationBasedCurrentMonth,
                      type: 'bar',
                      itemStyle: {color: '#C8102E', borderRadius: 5},
                      barWidth:13
                    }
                  ]
              }}/>
              <div style={{position: 'absolute', bottom: 26, left: 40, display: 'flex'}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.prevandcurrentquarter')}</p>
              </div>
              </>
            }
          </WhiteCard>
  
          <div className={classes.row}>
            <div className={classes.col60} ref={bucketAnalyticsTopCanSalesref}>
                <div className={classes.chartHeader}>
                  <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.TopCanSales')}</h2>
                  <div className={classes.chartRightDiv}>     
                    {/* <ChartButton clickDisable onClick={toggleTopCanSales}>
                      <CalendarIcon className={classes.CalendarIcon}/> {topCanSalesStartDate.formattedStandardDate} - {topCanSalesEndDate.formattedStandardDate}
                    </ChartButton>   */}
                    <ButtonDropdown 
                      label={"Last Year"}
                      options={[t('XdataAnalyticsDetail.lastyear'),t('XdataAnalyticsDetail.lastmonth'),t('XdataAnalyticsDetail.thisyear'), t('XdataAnalyticsDetail.thismonth')]}
                      selectedOption={topCanSalesSelectedOption}
                      annualSalesTrendSelectOption={topCanSalesSelectOptionChange}
                      selectedOptionClose={selectedOptionCloseStatus}
                      closeDropdownBtn={closeDropdownBtnStatus}     
                      mouseOverEvent={mouseOverEvent}  
                    />
                    <Tooltip title={t('XdataAnalyticsDetail.download')}>
                    <ChartButton clickDisable={topTopCanSalesXaxis.length === 0 ? true: false} onClick={() => exportToExcel(topCanSalesDownload, "Top Can Sales")}>
                      <DownloadIcon className={classes.DownloadIcon}/>
                    </ChartButton>
                    </Tooltip>
                  </div>            
                </div>
                <DateRangePicker
                // maxDate={new Date()}
                // minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                open={openTopCanSales}
                toggle={toggleTopCanSales}
                onChange={(range) => setDateRangeTopCanSales(range)}
                wrapperClassName={classes.wrapperClassNameSalesVolume}
              />
              {
                ccTopCanSalesLoading ? 
                <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccTopCanSalesError === 0 ? 
                <ErrorBlock btnClick={topCanTryClick} />
                  :
              topTopCanSalesXaxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <BarChart chartInfo={{
                  tooltip: {
                    trigger: 'axis',
                    position: function (pt) {
                      return [pt[0], '10%'];
                    }
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '6%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'category',
                    data: topTopCanSalesXaxis,
                  },
                  yAxis: {
                    type: 'value'
                  },
                  textStyle: {
                    fontFamily: 'Roboto',
                },
                  series: [                
                    {                  
                      data: topCanSalesData,
                      type: 'bar',
                      itemStyle: {color: '#C8102E', borderRadius: 5},
                      barWidth:13
                    }
                  ]
              }}/>
            }
            </div>
            <div className={classes.col30} ref={bucketAnalyticsCustomFormularef} style={{position: 'relative'}}>
                <div className={classes.datechartHeader}>
                    <h2 className={classes.chartTitleFullWidth}>{t('XdataAnalyticsDetail.SalesofCustomFormulaevsRegular')}</h2> 
                    <Tooltip title={t('XdataAnalyticsDetail.reload')}>
                      <ChartButton style={{padding: 8}} onClick={customFormulaTryClick}>
                        <AiOutlineReload className={classes.DownloadIcon}/>
                      </ChartButton>            
                    </Tooltip>              
                </div>
                
                {
                  
                  ccCustomFormulaSalesLoading ? 
                <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccCustomFormulaSalesError === 0 ? 
                <ErrorBlock btnClick={customFormulaTryClick} />
                  :
              customFormulaTotal === 0 ?
              <>
                <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              <div style={{position: 'absolute', display: 'flex', bottom: 20}}>
              <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
              <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.LastThirtydays')}</p>
            </div>
            </>
              :
                <>
                <PieChart chartInfo={{
                      tooltip: {
                        trigger: 'item'
                      },
                      legend: {
                        bottom: 0,
                        right: 0,
                        align: 'left'
                      },
                      title: {
                        text: t('xdataLocationDetails.total') + customFormulaTotal,
                        left: 'center',
                        top: 'center',
                    },
                    textStyle: {
                      fontFamily: 'Roboto',
                  },
                      series: [
                          {
                            stillShowZeroSum: false,
                              name: '',
                              type: 'pie',
                              radius: ['45%', '70%'],
                              avoidLabelOverlap: false,
                              label: {
                              show: false,
                              position: 'center'
                              },
                              emphasis: {
                              label: {
                                  show: false,
                                  fontSize: '18',
                                  fontWeight: 'bold',
                                  // formatter: () => {
                                  //   return "Total:" + customFormulaTotal; // Use sum variable here
                                  // },
                              }
                              },
                              labelLine: {
                              show: false
                              },
                              data: [
                                  { 
                                      value: customFormulaSalesRegular, 
                                      name: t('xdataLocationDetails.regular'), 
                                      itemStyle: { color: '#C8102E' },
                                      selected: true
                                  },
                                  { 
                                      value: customFormulaSalesCustom, 
                                      name: t('xdataLocationDetails.custom'), 
                                      itemStyle:{ color: '#36333d' }
                                  }
                              ]
                            }
                      ]
                    }}/>
                    <div style={{marginTop: '5px', display: 'flex'}}>
                    <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                    <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.last365Days')}</p>
                  </div>
                  </>
                  }
            </div>
          </div>
  
        </DashboardLayout>
        
      )
    }
  }
  else{
  if(user.is_workspace_graph === false){
    return (
      <DashboardLayout pageLoading={exportLoading}>
  
        <BreadCrumb linkData={linkData}/>
  
        <AnalyticsExporter code={code}/>
        {user.is_workspace_admin === true ? (
          <AlertSection>
            <a> { sendRequestLoading ? <>{t('XdataAnalyticsDetail.wait')} <CloudSyncIcon style={{color:"#C8102E"}} /></> : t('XdataAnalyticsDetail.noticeWorkspace')} </a>
            <IconButton  
              onClick={sendGraphReq}
            > 
            { sendRequestLoading ? null : <SendIcon style={{color:"#C8102E"}} /> }
            </IconButton>
          </AlertSection>) 
            : 
          (<AlertSection>
            {t('XdataAnalyticsDetail.notice')}
          </AlertSection>
        )}
        <FirstSection style={{filter: "blur(10px)", cursor: "default", pointerEvents: "none"}}>
  
          <RootContainer>
            <div className={classes.cardDiv}>
              <DashboardCard
                  errorStatus = {ccBucketAnalyticsError}
                  tryClick={bucketInformationTryClick}
                  errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsref}
                  title={t('XdataAnalyticsDetail.BucketInformation')}
                  height={200}
                  icon="document"
                  columnInfo3={true}
                  columnInfo3Data= { 
                    {
                      column1Title: t('XdataAnalyticsDetail.BucketID'),
                      column1Desc: code,
                      column2Title: t('XdataAnalyticsDetail.DataUsage'),
                      column2Desc: dataUsage,
                      column3Title: t('XdataAnalyticsDetail.Version'),
                      column3Desc: bucketAnalytics?.major_version + "." + bucketAnalytics?.minor_version,
                    }
                  }
                  footerRightContent = {
                    {
                      title: t('XdataAnalyticsDetail.DataLastSyncedat'),
                      desc: bucketAnalyticsSyncDate
                    }
                  }
                  loading={ccBucketAnalyticsLoading}
              />
              <DashboardCard
              errorStatus = {ccBucketAnalyticsVolumeTintedError}
              tryClick={volumeTintedTryClick}
              errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsVolumeTintedref}
                  title={t('XdataAnalyticsDetail.VolumeOrdersTinted')}
                  height={200}
                  icon="fill"
                  columnInfo2={true}
                  columnInfo2Data= { 
                    {
                      column1Title: t('XdataAnalyticsDetail.Volume'),
                      column1Desc: bucketAnalyticsVolumeTinted ? bucketAnalyticsVolumeTinted?.volume_tinted +  'L' : null ,
                      column2Title: t('XdataAnalyticsDetail.NoofOrders'),
                      column2Desc: bucketAnalyticsVolumeTinted?.orders,
                    }
                  }
                  footerLeftContent = {
                    {
                      desc: t('XdataAnalyticsDetail.Inlast24hours')
                    }
                  }
                  loading={ccBucketAnalyticsVolumeTintedLoading}
              />
            </div>
            <div className={classes.cardDiv}>
              <DashboardCard
               errorStatus = {ccBucketAnalyticsNewShopError}
               tryClick={newShopTryClick}
              errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsNewShopref}
                  title={t('XdataAnalyticsDetail.NewShopsOpened')}
                  height={200}
                  icon="shop"
                  footerLeftContent = {
                    {
                      title: bucketAnalyticsNewShop?.new_shops,
                      desc: t('XdataAnalyticsDetail.NewShopsOpenedThisYear')
                    }
                  }
                  loading={ccBucketAnalyticsNewShopLoading}
              />
              <DashboardCard
              tryClick={analyticsTryClick}
              errorStatus = {ccBucketAnalyticsLocationError}
              errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsLocationref}
                  title={t('XdataAnalyticsDetail.Locations')}
                  height={200}
                  icon="location"
                  footerLeftContent = {
                    {
                      title: bucketAnalyticsLocation?.no_of_shops,
                      button: {
                        text: t('XdataAnalyticsDetail.ViewAllLocations')
                      }
                    }
                  }
                  footerClick={footerClick}
                  loading={ccBucketAnalyticsLocationLoading}
              />
            </div>
          </RootContainer> 
  
          <MachineStatistics>
            <DashboardCard
              total={machineStatTotal}
              errorStatus = {ccMachineStatError}
              tryClick={machineStatTryClick}
              errorTopheight={"translateY(80%)"}
              observerref={bucketAnalyticsMachineStatref}
              title={t('XdataAnalyticsDetail.MachineDeploymentStatistics')}
              width="100%"
              height={430}
              loading={ccMachineStatLoading}
              chart={
                {
                  DoughnutChart: {
                    tooltip: {
                      trigger: 'item',
                      // position: function (pt) {
                      //   return [pt[0], '10%'];
                      // },
                      extraCssText: "background: #fff;color: #000; border-radius: 5px; font-size: 12px",
                      formatter: function(d) {
                        if(d.data.name === "Non-Operational"){
                          return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted2weeks') + "</div>";
                        }
                        else if(d.data.name === "Offline"){
                          return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted24hrs') + "</div>";
                        }
                        else {
                          return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.tinted24hrs') + "</div>";
                        }
                      }
                         
                    },
                    legend: {
                      bottom: 0,
                      align: 'left',
                      
                    },
                    title: {
                      text: t('XdataAnalyticsDetail.Total')+":" + machineStatTotal,
                      left: 'center',
                      top: 'center',
                   },
                   textStyle: {
                      fontFamily: 'Roboto',
                   },
                    series: [
                        {
                        bottom: 15,
                            stillShowZeroSum: false,
                            name: '',
                            type: 'pie',
                            radius: ['45%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                            show: false,
                            position: 'center'
                            },
                            emphasis: {
                            label: {
                                show: false,
                                fontSize: '18',
                                fontWeight: 'bold',
                                // formatter: () => {
                                //   return "Total:" + machineStatTotal; // Use sum variable here
                                // },
                            }
                            },
                            labelLine: {
                            show: false
                            },
                          
                            data: [
                                { 
                                    value: machineStatNonOperational, 
                                    name: t('XdataAnalyticsDetail.NonOperational'), 
                                    itemStyle: { color: '#C8102E' } 
                                },
                                { 
                                    value: machineStatOnline, 
                                    name: t('XdataAnalyticsDetail.Online'), 
                                    itemStyle:{ color: '#36333d' } 
                                },
                                { 
                                    value: machineStatOffline, 
                                    name: t('XdataAnalyticsDetail.Offline'), 
                                    itemStyle:{ color: '#a2aaad' } 
                                },
                            ]
                          }
                    ]
                  },
                  NestedPie: {
                    tooltip: {
                      trigger: 'item'
                    },
                    legend: {
                      bottom: -7,
                      align: 'left'
                    },
                    textStyle: {
                      fontFamily: 'Roboto'
                   },
                    series: [
                      
                      {
                        stillShowZeroSum: false,
                        name: '',
                        type: 'pie',
                        radius: ['45%', '70%'],
                        labelLine: {
                          length: 30
                        },
                        label: {
                          show: false,
                        },
                        data: staticData,
                        bottom: 15
                      },
                      {
                        bottom: 15,
                        stillShowZeroSum: false,
                        name: '',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '25%'],
                        label: {
                          show: false,
                        },
                        labelLine: {
                          show: false
                        },
                        selectedOffset: 0,
                        data: [
                          { value: machineStatNewerVersions, name: t('XdataAnalyticsDetail.NewerVersions'), itemStyle:{ color: '#FF2C4F' } },
                          { value: machineStatOlderVersions, name: t('XdataAnalyticsDetail.OlderVersions'), itemStyle:{ color: '#5A5762' }, selected: true }
                        ]
                      }
                    ]
                  }
                }
              }
            />
          </MachineStatistics>
  
        </FirstSection>
  
        <div></div>
  
      </DashboardLayout>
      
    )
    }
  else if(user.is_workspace_graph === true){
    return (
      <DashboardLayout pageLoading={exportLoading}>

        <BreadCrumb linkData={linkData}/>

        
        <AnalyticsExporter code={code}/>
        <FirstSection>

          <RootContainer>
            <div className={classes.cardDiv}>
              <DashboardCard
                  errorStatus = {ccBucketAnalyticsError}
                  tryClick={bucketInformationTryClick}
                  errorTopheight={"translateY(80%)"}
                  observerref={bucketAnalyticsref}
                  title={t('XdataAnalyticsDetail.BucketInformation')}
                  height={200}
                  icon="document"
                  columnInfo3={true}
                  columnInfo3Data= { 
                    {
                      column1Title: t('XdataAnalyticsDetail.BucketID'),
                      column1Desc: code,
                      column2Title: t('XdataAnalyticsDetail.DataUsage'),
                      column2Desc: dataUsage,
                      column3Title: t('XdataAnalyticsDetail.Version'),
                      column3Desc: bucketAnalytics?.major_version + "." + bucketAnalytics?.minor_version,
                    }
                  }
                  footerRightContent = {
                    {
                      title: t('XdataAnalyticsDetail.DataLastSyncedat'),
                      desc: bucketAnalyticsSyncDate
                    }
                  }
                  loading={ccBucketAnalyticsLoading}
              />
              <DashboardCard
              errorStatus = {ccBucketAnalyticsVolumeTintedError}
              tryClick={volumeTintedTryClick}
              errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsVolumeTintedref}
                  title={t('XdataAnalyticsDetail.VolumeOrdersTinted')}
                  height={200}
                  icon="fill"
                  columnInfo2={true}
                  columnInfo2Data= { 
                    {
                      column1Title: t('XdataAnalyticsDetail.Volume'),
                      column1Desc: bucketAnalyticsVolumeTinted ? bucketAnalyticsVolumeTinted?.volume_tinted +  'L' : null ,
                      column2Title: t('XdataAnalyticsDetail.NoofOrders'),
                      column2Desc: bucketAnalyticsVolumeTinted?.orders,
                    }
                  }
                  footerLeftContent = {
                    {
                      desc: t('XdataAnalyticsDetail.Inlast24hours')
                    }
                  }
                  loading={ccBucketAnalyticsVolumeTintedLoading}
              />
            </div>
            <div className={classes.cardDiv}>
              <DashboardCard
              errorStatus = {ccBucketAnalyticsNewShopError}
              tryClick={newShopTryClick}
              errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsNewShopref}
                  title={t('XdataAnalyticsDetail.NewShopsOpened')}
                  height={200}
                  icon="shop"
                  footerLeftContent = {
                    {
                      title: bucketAnalyticsNewShop?.new_shops,
                      desc: t('XdataAnalyticsDetail.NewShopsOpenedThisYear')
                    }
                  }
                  loading={ccBucketAnalyticsNewShopLoading}
              />
              <DashboardCard
              tryClick={analyticsTryClick}
              errorStatus = {ccBucketAnalyticsLocationError}
              errorTopheight={"translateY(80%)"}
                observerref={bucketAnalyticsLocationref}
                  title={t('XdataAnalyticsDetail.Locations')}
                  height={200}
                  icon="location"
                  footerLeftContent = {
                    {
                      title: bucketAnalyticsLocation?.no_of_shops,
                      button: {
                        text: t('XdataAnalyticsDetail.ViewAllLocations')
                      }
                    }
                  }
                  footerClick={footerClick}
                  loading={ccBucketAnalyticsLocationLoading}
              />
            </div>
          </RootContainer> 

          <MachineStatistics>
            <DashboardCard
              total={machineStatTotal}
              errorStatus = {ccMachineStatError}
              tryClick={machineStatTryClick}
              errorTopheight={"translateY(80%)"}
              observerref={bucketAnalyticsMachineStatref}
              title={t('XdataAnalyticsDetail.MachineDeploymentStatistics')}
              width="100%"
              height={430}
              loading={ccMachineStatLoading}
              chart={
                {
                  DoughnutChart: {
                    tooltip: {
                      trigger: 'item',
                      // position: function (pt) {
                      //   return [pt[0], '10%'];
                      // },
                      extraCssText: "background: #fff;color: #000; border-radius: 5px; font-size: 12px",
                      formatter: function(d) {
                        if(d.data.name === "Non-Operational"){
                          return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted2weeks') + "</div>";
                        }
                        else if(d.data.name === "Offline"){
                          return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.notTinted24hrs') + "</div>";
                        }
                        else {
                          return "<div style='opacity: 0.7;font-size: 15px'>" + d.data.name  + verticalLine +"<b>"+ d.data.value + "</b><br />" + t('XdataAnalyticsDetail.tinted24hrs') + "</div>";
                        }
                      }
                        
                    },
                    legend: {
                      bottom: 0,
                      align: 'left',
                      
                    },
                    title: {
                      text: t('XdataAnalyticsDetail.Total')+":" + machineStatTotal,
                      left: 'center',
                      top: 'center',
                  },
                  textStyle: {
                      fontFamily: 'Roboto',
                  },
                    series: [
                        {
                        bottom: 15,
                            stillShowZeroSum: false,
                            name: '',
                            type: 'pie',
                            radius: ['45%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                            show: false,
                            position: 'center'
                            },
                            emphasis: {
                            label: {
                                show: false,
                                fontSize: '18',
                                fontWeight: 'bold',
                                // formatter: () => {
                                //   return "Total:" + machineStatTotal; // Use sum variable here
                                // },
                            }
                            },
                            labelLine: {
                            show: false
                            },
                          
                            data: [
                                { 
                                    value: machineStatNonOperational, 
                                    name: t('XdataAnalyticsDetail.NonOperational'), 
                                    itemStyle: { color: '#C8102E' } 
                                },
                                { 
                                    value: machineStatOnline, 
                                    name: t('XdataAnalyticsDetail.Online'), 
                                    itemStyle:{ color: '#36333d' } 
                                },
                                { 
                                    value: machineStatOffline, 
                                    name: t('XdataAnalyticsDetail.Offline'), 
                                    itemStyle:{ color: '#a2aaad' } 
                                },
                            ]
                          }
                    ]
                  },
                  NestedPie: {
                    tooltip: {
                      trigger: 'item'
                    },
                    legend: {
                      bottom: -7,
                      align: 'left'
                    },
                    textStyle: {
                      fontFamily: 'Roboto'
                  },
                    series: [
                      
                      {
                        stillShowZeroSum: false,
                        name: '',
                        type: 'pie',
                        radius: ['45%', '70%'],
                        labelLine: {
                          length: 30
                        },
                        label: {
                          show: false,
                        },
                        data: staticData,
                        bottom: 15
                      },
                      {
                        bottom: 15,
                        stillShowZeroSum: false,
                        name: '',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '25%'],
                        label: {
                          show: false,
                        },
                        labelLine: {
                          show: false
                        },
                        selectedOffset: 0,
                        data: [
                          { value: machineStatNewerVersions, name: t('XdataAnalyticsDetail.NewerVersions'), itemStyle:{ color: '#b5b5b5' } },
                          { value: machineStatOlderVersions, name: t('XdataAnalyticsDetail.OlderVersions'), itemStyle:{ color: '#5A5762' }, selected: true }
                        ]
                      }
                    ]
                  }
                }
              }
            />
          </MachineStatistics>

        </FirstSection>

        <WhiteCard ref={bucketAnalyticsSalesVolumeref}>
          <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.SalesVolume')}</h2>
              <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                    <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={salesVolumeLitreOrOrder === true} onChange={(event) => setSalesVolumeLiterOrOrder(true)}  style ={{color: "#C8102E"}} />} 
                          label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                      <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={salesVolumeLitreOrOrder === false} onChange={(event) => setSalesVolumeLiterOrOrder(false)}  style ={{color: "#C8102E"}} />} 
                      label={t('XdataAnalyticsDetail.LitersSold')}/>
                </div>
                <ChartButton onClick={toggleSalesVolume}  style={{height: 32,paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                  <CalendarIcon className={classes.CalendarIcon}/> {salesVolumeStartDate.formattedStandardDate} - {salesVolumeEndDate.formattedStandardDate}
                </ChartButton>    
                <Tooltip title={t('XdataAnalyticsDetail.download')}>      
                  <ChartButton  clickDisable={annualSalesVolumeXaxis.length === 0 ? true: false} onClick={() => exportToExcel(annualSalesVolumeDownload, "Sales Volume")} style={{height: 32,paddingTop: 5, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                </Tooltip>    
              </div>                        
          </div>
          <DateRangePicker
            maxDate={new Date(new Date().setDate(new Date().getDate()))}
            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
            definedRanges={["Last Month", "This Year"]}
            open={openSalesVolume}
            toggle={toggleSalesVolume}
            onChange={(range) => setDateRangeSalesVolume(range)}
            wrapperClassName={classes.wrapperClassNameSalesVolume}
          />
          {
            ccAnnualSalesVolumeLoading  ? 
                  <div className={classes.loaderDiv}>
                      <CircularProgress color="secondary" sx={{color:'#C8102E'}}/>
                  </div> 
                :
              ccAnnualSalesVolumeError === 0 ? 
                <ErrorBlock btnClick={salesVolumeTryClick} />
                  :
            annualSalesVolumeXaxis.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            :
            <>
            <AreaChart chartInfo={
              {
                tooltip: {
                  trigger: 'axis',
                  // position: function (pt) {
                  //   return [pt[0], '10%'];
                  // }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '15%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: annualSalesVolumeXaxis,
                  axisLabel: {
                    showMaxLabel: true
                  }             
                },
                yAxis: {
                  type: 'value',
                  axisLabel: {
                    formatter: '{value}', // {value} L
                    margin: 10,
                  }
                },
                dataZoom: [
                  {
                    type: 'inside',
                    start: 0,
                    end: 100
                  },
                  {
                    start: 0,
                    end: 100,
                    borderColor: "#FFB1A8",
                    backgroundColor: "#FFB1A8",
                    height: 30,
                    moveHandleStyle:{
                      color: "#C8102E",
                      opacity: 1
                    },
                    dataBackground:{
                        lineStyle: {
                          color: '#C8102E',
                          width: 0.5
                        },
                        areaStyle: {
                          color: '#C8102E',
                          width: 0.5
                        }
                    },
                    selectedDataBackground:{
                      lineStyle: {
                        color: '#C8102E',
                        width: 0.5
                      },
                      areaStyle: {
                        color: '#C8102E',
                        width: 0.5
                      }
                  },
                    emphasis:{
                      moveHandleStyle: {
                        color: "#C8102E",
                        opacity: 0.5
                      }
                    },
                    textStyle: {
                      fontFamily: 'Roboto',
                      color: '#000'
                    }
                  }
                ],
                textStyle: {
                  fontFamily: 'Roboto',
              },
                series: [
                  {
                    data: annualSalesVolumeYaxis,
                    type: 'line',
                    smooth: true,
                    itemStyle: {color: '#C8102E'},
                    lineStyle: {color: '#C8102E'},
                    areaStyle: {color: '#FFB1A8'},
                  }
                ]
              }
            }/>
            {
              salesVolumeLitreOrOrder === false ? 
                <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
              </div>:
              null
            }
            
            </>
          }
          
        </WhiteCard>

        <WhiteCard ref={Annualref}>
          <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.AnnualSalesTrend')}</h2>
              <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                <span className={classes.chartRightChild}>
                  <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                      <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={annualsalesLitreOrOrder === true} onChange={(event) => setAnnualsalesLitreOrOrder(true)}  style ={{color: "#C8102E"}} />} 
                            label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                        <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={annualsalesLitreOrOrder === false} onChange={(event) => setAnnualsalesLitreOrOrder(false)}  style ={{color: "#C8102E"}} />} 
                        label={t('XdataAnalyticsDetail.LitersSold')}/>
                  </div>
                </span>
                <ButtonDropdown 
                  label={t('XdataAnalyticsDetail.ViewGraph')}
                  options={[t('XdataAnalyticsDetail.ViewGraph'), t('XdataAnalyticsDetail.ViewTable')]}
                  selectedOption={annualSalesTrendSelectedOption}
                  annualSalesTrendSelectOption={annualSalesTrendSelectOption}
                  selectedOptionClose={selectedOptionCloseStatus}
                  closeDropdownBtn={closeDropdownBtnStatus}     
                  mouseOverEvent={mouseOverEvent}  
                />
                <Tooltip title={t('XdataAnalyticsDetail.download')}> 
                <ChartButton clickDisable={annualSalesTrendXaxis.length === 0 ? true: false} onClick={() => exportToExcel(annualSalesTrendDownload, "Annual Sales Trend")} style={{height: 32,paddingTop: 5, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                  <DownloadIcon className={classes.DownloadIcon}/>
                </ChartButton>
                </Tooltip>
              </div>            
          </div>
          {annualSalesTrendSelectedOption=== t('XdataAnalyticsDetail.ViewGraph') ? 
          ccAnnualSalesTrendLoading ? 
          <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccAnnualSalesTrendError === 0 ? 
                <ErrorBlock btnClick={annualSalesTrendTryClick}  />
                  :
            annualSalesTrendXaxis.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            :
            <>
            <AreaChart chartInfo={
            {
              tooltip: {
                trigger: 'axis',
                // position: function (pt) {
                //   return [pt[0], '10%'];
                // }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: annualSalesTrendXaxis,
                axisLabel: {
                  showMaxLabel: true
                }           
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: '{value}', // {value} L
                  margin: 10,
                }
              },
              textStyle: {
                fontFamily: 'Roboto',
            },
              series: [
                {
                  data: annualSalesTrendYaxis,
                  type: 'line',
                  smooth: true,
                  itemStyle: {color: '#C8102E'},
                  lineStyle: {color: '#C8102E'},
                }
              ]
            }
          }/>
          {
            annualsalesLitreOrOrder === false ? 
              <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>:
            null
          }
          </>
          :
          annualSalesTrendXaxis.length === 0 ? 
          <div className={classes.loaderDiv}>
            <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
          </div>
            :
            <>
          <Table sx={{ marginTop: 5 }} size="small">
          <TableHead>
            <TableRow sx={{ 'td, th': { border: '1px solid #A7A7A7', background:'#DDDDDD', fontWeight: 'bold' } }}>
              <TableCell></TableCell>
              {annualSalesTrendXaxis.map((row) => (
                <TableCell align="center">{row}</TableCell>   
              ))}        
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Jan')}
                </TableCell>
                
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "January") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
                
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Feb')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "February") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Mar')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "March") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Apr')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "April") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.May')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "May") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Jun')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "June") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Jul')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "July") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Aug')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "August") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Sep')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "September") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Oct')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "October") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Nov')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "November") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td': { border: '1px solid #A7A7A7', color:'#36333D' }, 'th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                {t('XdataAnalyticsDetail.Dec')}
                </TableCell>
                {annualSalesTrendXaxis.map((row) => (
                  <TableCell align="center">
                  {ccAnnualSalesTrendSuccess?.data?.result.map((data) => (
                    (row === data.year)&&
                      data.monthly_sales.map((month_v, month_k)=> (                      
                          (month_v.month === "December") ? month_v.total : ''                       
                      ))                  
                  ))}
                  </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{ 'td, th': { border: '1px solid #A7A7A7', color:'#36333D', fontWeight: 'bold' } }}
              >
                <TableCell align="center" component="th">
                  {t('XdataAnalyticsDetail.Total')}
                </TableCell>
                {annualSalesTrendYaxis.map((rowTotal) => (
                  <TableCell align="center">{rowTotal}</TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
        {
            annualsalesLitreOrOrder === false ? 
              <div style={{display: 'flex', marginLeft: 20, marginTop: 10}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>:
            null
          }
          </>
        }
        
        </WhiteCard>

        <DoubleWhiteCard>
          <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.TopSalesStatistics')}</h2>
              <div className={classes.chartRightDiv} style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                <span className={classes.chartRightChild}>
                  <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                      <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={topStatisticsLitreOrOrder === true} onChange={(event) => setTopStatisticsLitreOrder(true)}  style ={{color: "#C8102E"}} />} 
                            label={t('XdataAnalyticsDetail.OrdersTinted')}/>
                        <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={topStatisticsLitreOrOrder === false} onChange={(event) => setTopStatisticsLitreOrder(false)}  style ={{color: "#C8102E"}} />} 
                        label={t('XdataAnalyticsDetail.LitersSold')}/>
                  </div>
                </span>
                {/* <ChartButton clickDisable onClick={toggleTopSaleStatistics} style={{height: 32,paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
                  <CalendarIcon className={classes.CalendarIcon}/> {topSaleStatisticsStartDate.formattedStandardDate} - {topSaleStatisticsEndDate.formattedStandardDate}
                </ChartButton>               */}
                <ButtonDropdown 
                  label={t('XdataAnalyticsDetail.lastyear')}
                  options={[t('XdataAnalyticsDetail.lastyear'),t('XdataAnalyticsDetail.lastmonth'),t('XdataAnalyticsDetail.thisyear'), t('XdataAnalyticsDetail.thismonth')]}
                  selectedOption={topSalesStaticsSelectedOption}
                  annualSalesTrendSelectOption={topSalesStaticsSelectOptionChange}
                  selectedOptionClose={selectedOptionCloseStatus}
                  closeDropdownBtn={closeDropdownBtnStatus}     
                  mouseOverEvent={mouseOverEvent}  
                />
              </div>            
          </div>
          <DateRangePicker
            // maxDate={new Date()}
            // minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
            open={openTopSaleStatistics}
            toggle={toggleTopSaleStatistics}
            onChange={(range) => setDateRangeTopSaleStatistics(range)}
            wrapperClassName={classes.wrapperClassNameSalesVolume}
          />
          <div className={classes.DoubleWhiteCardChild} ref={bucketAnalyticsTopTenColorsref}>
            <div className={classes.chartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.Colors')}</h2>
                <div className={classes.chartRightDiv}>
                <Tooltip title={t('XdataAnalyticsDetail.download')}> 
                  <ChartButton clickDisable={topTenColorsData.length === 0 ? true: false} onClick={() => exportToExcel(topSaleStatisticsDownload, "Top Sales Statistics")}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                  </Tooltip>
                </div>            
            </div>
            {
            ccTopTenColorsLoading ? 
            <div className={classes.loaderDiv}>
                      <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                  </div>:
            ccTopTenColorsError === 0 ? 
            <ErrorBlock btnClick={toptenColorsTryClick} />
              :
            topTenColorsData.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            :
            <FunnelChart chartInfo={{
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}'
              },
              legend: {
                right: 50,
                orient: 'vertical',
                // padding: 50,
                // itemGap: 10,
              },
              textStyle: {
                fontFamily: 'Roboto',
            },
              series: [
                {
                  left: '0%',
                  name: t('XdataAnalyticsDetail.Colors'),
                  type: 'funnel',
                  sort: 'ascending',
                  width: '50%',
                  gap: 4,
                  label: {
                    show: true,
                    color: 'black',
                  },
                  labelLine: {
                    length: 30,
                    lineStyle: {
                      width: 1,
                      type: 'solid'
                    }
                  },
                  itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1
                  },
                  emphasis: {
                    label: {
                      fontSize: 20
                    }
                  },
                  data: topTenColorsData
                }
              ]
            }}/>
          }
          </div>
          <div className={classes.DoubleWhiteCardChild} ref={bucketAnalyticsTopTenProductsref}>
            <div className={classes.chartHeader}>
                <div className={classes.chartLeftBtns}>
                  {activeProductBtn === "product" ? (<ChartButtonActive>{t('XdataAnalyticsDetail.Product')}</ChartButtonActive>) : (<ChartButtonWithoutBg onClick={chartButtonTypeChange('product')}>{t('XdataAnalyticsDetail.Product')}</ChartButtonWithoutBg>)}
                  {activeProductBtn === "subproduct" ? (<ChartButtonActive>{t('XdataAnalyticsDetail.Subproduct')}</ChartButtonActive>) : (<ChartButtonWithoutBg onClick={chartButtonTypeChange('subproduct')}>{t('XdataAnalyticsDetail.Subproduct')}</ChartButtonWithoutBg>)}
                  {activeProductBtn === "base" ? (<ChartButtonActive>{t('XdataAnalyticsDetail.Base')}</ChartButtonActive>) : (<ChartButtonWithoutBg onClick={chartButtonTypeChange('base')}>{t('XdataAnalyticsDetail.Base')}</ChartButtonWithoutBg>)}                
                </div>
                <div className={classes.chartRightDiv}>
                <Tooltip title={t('XdataAnalyticsDetail.download')}> 
                  <ChartButton clickDisable={topTenProductsYaxis.length === 0 ? true: false} onClick={() =>{activeProductBtn === "product"? exportToExcel(topTenProductDownload, "Top Products") :activeProductBtn === "subproduct"? exportToExcel(topTenProductDownload, "Top SubProducts"): exportToExcel(topTenProductDownload, "Top Base")}}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                  </Tooltip>
                </div>            
            </div>
            {
              ccTopTenProductsLoading ? 
              <div className={classes.loaderDiv}>
                        <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                    </div>:
                    ccTopTenProductsError === 0 ? 
                    <ErrorBlock btnClick={topTenProductsTryClick} />
                      :
            topTenProductsYaxis.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            :
            <BarChart chartInfo={{
              tooltip: {
                trigger: 'axis',
                // position: function (pt) {
                //   return [pt[0], '10%'];
                // },
                extraCssText: "background: #36333D;color: #FFFFFF; border-radius: 5px; font-size: 12px",
                formatter: function(d) {
                  return d[0].name  + verticalLine + d[0].data;
                }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '5%',
                containLabel: true
              },
            
              xAxis: {
                type: 'value',
              },
              yAxis: {
                type: 'category',
                data: topTenProductsYaxis
              },
              textStyle: {
                fontFamily: 'Roboto',
            },
              series: [
                {
                  data: topTenProductsData,
                  type: 'bar',
                  itemStyle: {color: '#FF8397'},
                  barWidth:25
                }
              ]
            }} />
          }
          </div>
          {
              topStatisticsLitreOrOrder === false ? 
                <div style={{display: 'flex', marginTop: 10}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
              </div>:
              null
            }
        </DoubleWhiteCard>

        <WhiteCard ref={colorantConsumptionref}>
          <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.ColorantConsumption')}</h2>
              <div className={classes.chartRightDiv}>
              <ChartButton  onClick={toggleColorantConsumption}>
                <CalendarIcon className={classes.CalendarIcon}/> {colorantConsumptionStartDate.formattedStandardDate} - {colorantConsumptionEndDate.formattedStandardDate}
              </ChartButton> 
              <Tooltip title={t('XdataAnalyticsDetail.download')}>             
                <ChartButton clickDisable={colorantConsumptionXAxis.length === 0 ? true: false} onClick={() => exportToExcel(colorantConsumptionDownload, "Colorant Consumption")}>
                  <DownloadIcon className={classes.DownloadIcon}/>
                </ChartButton>
                </Tooltip>
              </div>            
          </div>
          <DateRangePicker
            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
            definedRanges={["Last Month", "This Year"]}
            open={openColorantConsumption}
            toggle={toggleColorantConsumption}
            onChange={(range) => setDateRangeColorantConsumption(range)}
            wrapperClassName={classes.wrapperClassNameSalesVolume}
          />
          {
            ccBucketAnalyticsColorantConsumptionLoading ? 
            <div className={classes.loaderDiv}>
                <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
            </div>:
            ccBucketAnalyticsColorantConsumptionError === 0 ? 
            <ErrorBlock btnClick={colorantConsumptionTryClick} />
              :
            colorantConsumptionXAxis.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            :
            <>
          <AreaChart chartInfo={
            {
              tooltip: {
                trigger: 'axis',
               
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '13%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: colorantConsumptionXAxis,
                axisLabel: {
                  showMaxLabel: true
                }             
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: '{value}', // {value} L
                  margin: 10,
                }
              },
              dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: 100
                },
                {
                  start: 0,
                  end: 100,
                  borderColor: "#FFB1A8",
                  backgroundColor: "#FFB1A8",
                  height: 30,
                  moveHandleStyle:{
                    color: "#C8102E",
                    opacity: 1
                  },
                  dataBackground:{
                      lineStyle: {
                        color: '#C8102E',
                        width: 0.5
                      },
                      areaStyle: {
                        color: '#C8102E',
                        width: 0.5
                      }
                  },
                  selectedDataBackground:{
                    lineStyle: {
                      color: '#C8102E',
                      width: 0.5
                    },
                    areaStyle: {
                      color: '#C8102E',
                      width: 0.5
                    }
                },
                  emphasis:{
                    moveHandleStyle: {
                      color: "#C8102E",
                      opacity: 0.5
                    }
                  },
                  textStyle: {
                    color: '#000'
                  }
                }
              ],
              series: colorantConsumptionSeries
            }
          }/>
          <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
              <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
              <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
          </div>
          </>
        }
        </WhiteCard>

        <WhiteCard ref={bucketAnalyticsColorantConsumptionBarref}>
            
            {/* <div ref={bucketAnalyticsColorantConsumptionBarref}> */}
                <div className={classes.chartHeader}>
                  <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.ColorantConsumptionBarChart')}</h2>
                  <div className={classes.chartRightDiv}>     
                    {/* <ChartButton clickDisable onClick={toggleTopCanSales}>
                      <CalendarIcon className={classes.CalendarIcon}/> {topCanSalesStartDate.formattedStandardDate} - {topCanSalesEndDate.formattedStandardDate}
                    </ChartButton>   */}
                    <ButtonDropdown 
                      label={"Last Year"}
                      options={[t('XdataAnalyticsDetail.lastyear'),t('XdataAnalyticsDetail.lastmonth'),t('XdataAnalyticsDetail.thisyear'), t('XdataAnalyticsDetail.thismonth')]}
                      selectedOption={colorantConsumptionBarSelectedOption}
                      annualSalesTrendSelectOption={colorantConsumptionBarSelectOptionChange}
                      selectedOptionClose={selectedOptionCloseStatus}
                      closeDropdownBtn={closeDropdownBtnStatus}     
                      mouseOverEvent={mouseOverEvent}  
                    />
                    <Tooltip title={t('XdataAnalyticsDetail.download')}>
                    <ChartButton clickDisable={colorantConsumptionBarXaxis.length === 0 ? true: false} onClick={() => exportToExcel(colorantConsumptionBarDownload, "colorant Consumption BarGraph details")}>
                      <DownloadIcon className={classes.DownloadIcon}/>
                    </ChartButton>
                    </Tooltip>
                  </div>            
                </div>
                <DateRangePicker
                // maxDate={new Date()}
                // minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                open={openColorantConsumptionBar}
                toggle={toggleColorantConsumptionBar}
                onChange={(range) => setDateRangeColorantConsumptionBar(range)}
                wrapperClassName={classes.wrapperClassNameSalesVolume}
              />
              {
                ccBucketAnalyticsColorantConsumptionBarLoading ? 
                <div className={classes.loaderDiv}>
                    <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
                </div>:
                ccBucketAnalyticsColorantConsumptionBarError === 0 ? 
                <ErrorBlock btnClick={colorantConsumptionBarTryClick} />
                  :
              colorantConsumptionBarXaxis.length === 0 ? 
              <div className={classes.loaderDiv}>
                <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
              </div>
              :
              <>
              <BarChart chartInfo={{
                  tooltip: {
                    trigger: 'axis',
                    position: function (pt) {
                      return [pt[0], '10%'];
                    }
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '6%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'category',
                    data: colorantConsumptionBarXaxis,
                    axisLabel: {
                      interval: 0,
                      rotate: 30 //If the label names are too long you can manage this by rotating the label.
                    },
                    axisTick: {
                      alignWithLabel: true
                    }
                  },
                  yAxis: {
                    type: 'value',
                  },
                  textStyle: {
                    fontFamily: 'Roboto',
                },
                  series: [                
                      {                  
                        data: colorantConsumptionBarData,
                        type: 'bar',
                        itemStyle: {borderRadius: 5,
                          color: function (params) {
                          const color = colorantConsumptionBarColor[params.dataIndex % colorantConsumptionBarColor.length];
                          return color;
                        },
                        barWidth: 13,
                        borderColor: '#000000', // Set the border color to black
                        borderWidth: 0.5 // Set the border width
                      }
                    }
                    ]
                  
              }}/>
              <div style={{display: 'flex', marginLeft: 40, marginTop: 10}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.allValuesinLitre')}</p>
            </div>
            </>
            }
            {/* </div> */}
            
        </WhiteCard>

        <WhiteCard ref={bucketAnalyticsLocationBasedSalesref}>
          <div className={classes.datechartHeader}>
              <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.LocationBasedSales')}</h2>
              <div className={classes.datechartRightDiv}  style={{display: 'flex', justifyContent: 'end', alignItems: 'center', width: '50%'}}>

              <div className='radioButtonStyle' style={{display: 'inline-grid'}}>
                      <FormControlLabel  name="radio-buttons" value={true}  control={<Radio checked={locationBasedTopTen === true} onChange={(event) => setLocationBasedTopTen(true)}  style ={{color: "#C8102E"}} />} 
                            label={t('XdataAnalyticsDetail.TopTen')}/>
                        <FormControlLabel style={{marginTop: 3}}  name="radio-buttons" value={false}  control={<Radio checked={locationBasedTopTen === false} onChange={(event) => setLocationBasedTopTen(false)}  style ={{color: "#C8102E"}} />} 
                        label={t('XdataAnalyticsDetail.WorstTen')}/>
                  </div>
                <Tooltip title={t('XdataAnalyticsDetail.reload')}>
                  <ChartButton  onClick={locationBasedTryClick} style={{marginRight: 5}}>
                    <AiOutlineReload className={classes.DownloadIcon}/>
                  </ChartButton>  
                </Tooltip>
                
                <Tooltip title={t('XdataAnalyticsDetail.download')}>
                <ChartButton clickDisable={locationBasedPosData.length === 0 ? true: false} style={{padding: 8}} onClick={() => exportToExcel(locationBasedDownload, "Location Based Sales")}>
                  <DownloadIcon className={classes.DownloadIcon}/>
                </ChartButton>
                </Tooltip>
              </div>
          </div>
          {
            ccBucketAnalyticsLocationBasedSalesLoading ? 
            <div className={classes.loaderDiv}>
                <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
            </div>:
            ccBucketAnalyticsLocationBasedSalesError === 0 ? 
            <ErrorBlock btnClick ={locationBasedTryClick} />
              :
            locationBasedPosData.length === 0 ? 
            <>
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            <div style={{position: 'absolute', bottom: 26, left: 40, display: 'flex'}}>
                <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.prevandcurrentquarter')}</p>
              </div>
            </>
            :
            <>
          <BarChart height={430} chartInfo={{
                tooltip: {
                  trigger: 'axis',
                  position: function (pt) {
                    return [pt[0], '10%'];
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '9%',
                  containLabel: true
                },
                legend: {
                  bottom: -5,
                  right: 0,
                  align: 'left',
                },
                xAxis: {
                  type: 'category',
                  data: locationBasedPosData,
                  axisLabel: {
                    width: locationBasedPosData.length > 5 ?  80: 100, 
                    overflow: 'truncate',
                    interval: 0,
                  },
                  axisTick:{
                    alignWithLabel: true
                  }
                },
                yAxis: {
                  type: 'value'
                },
                textStyle: {
                  fontFamily: 'Roboto',
              },
                series: [
                  {
                    name: t('XdataAnalyticsDetail.prevQuarter'),
                    data: locationBasedPreviousMonth,
                    type: 'bar',
                    itemStyle: {color: '#FFA1B0', borderRadius: 5},
                    barWidth:13
                  },
                  {
                    name: t('XdataAnalyticsDetail.currentQuarter'),
                    data: locationBasedCurrentMonth,
                    type: 'bar',
                    itemStyle: {color: '#C8102E', borderRadius: 5},
                    barWidth:13
                  }
                ]
            }}/>
            <div style={{position: 'absolute', bottom: 26, left: 40, display: 'flex'}}>
              <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
              <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.prevandcurrentquarter')}</p>
            </div>
            </>
          }
        </WhiteCard>

        <div className={classes.row}>
          <div className={classes.col60} ref={bucketAnalyticsTopCanSalesref}>
              <div className={classes.chartHeader}>
                <h2 className={classes.chartTitle}>{t('XdataAnalyticsDetail.TopCanSales')}</h2>
                <div className={classes.chartRightDiv}>     
                  {/* <ChartButton clickDisable onClick={toggleTopCanSales}>
                    <CalendarIcon className={classes.CalendarIcon}/> {topCanSalesStartDate.formattedStandardDate} - {topCanSalesEndDate.formattedStandardDate}
                  </ChartButton>   */}
                  <ButtonDropdown 
                    label={"Last Year"}
                    options={[t('XdataAnalyticsDetail.lastyear'),t('XdataAnalyticsDetail.lastmonth'),t('XdataAnalyticsDetail.thisyear'), t('XdataAnalyticsDetail.thismonth')]}
                    selectedOption={topCanSalesSelectedOption}
                    annualSalesTrendSelectOption={topCanSalesSelectOptionChange}
                    selectedOptionClose={selectedOptionCloseStatus}
                    closeDropdownBtn={closeDropdownBtnStatus}     
                    mouseOverEvent={mouseOverEvent}  
                  />
                  <Tooltip title={t('XdataAnalyticsDetail.download')}>
                  <ChartButton clickDisable={topTopCanSalesXaxis.length === 0 ? true: false} onClick={() => exportToExcel(topCanSalesDownload, "Top Can Sales")}>
                    <DownloadIcon className={classes.DownloadIcon}/>
                  </ChartButton>
                  </Tooltip>
                </div>            
              </div>
              <DateRangePicker
              // maxDate={new Date()}
              // minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
              open={openTopCanSales}
              toggle={toggleTopCanSales}
              onChange={(range) => setDateRangeTopCanSales(range)}
              wrapperClassName={classes.wrapperClassNameSalesVolume}
            />
            {
              ccTopCanSalesLoading ? 
              <div className={classes.loaderDiv}>
                  <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
              </div>:
              ccTopCanSalesError === 0 ? 
              <ErrorBlock btnClick={topCanTryClick} />
                :
            topTopCanSalesXaxis.length === 0 ? 
            <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            :
            <BarChart chartInfo={{
                tooltip: {
                  trigger: 'axis',
                  position: function (pt) {
                    return [pt[0], '10%'];
                  }
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '6%',
                  containLabel: true
                },
                xAxis: {
                  type: 'category',
                  data: topTopCanSalesXaxis,
                },
                yAxis: {
                  type: 'value'
                },
                textStyle: {
                  fontFamily: 'Roboto',
              },
                series: [                
                  {                  
                    data: topCanSalesData,
                    type: 'bar',
                    itemStyle: {color: '#C8102E', borderRadius: 5},
                    barWidth:13
                  }
                ]
            }}/>
          }
          </div>
          <div className={classes.col30} ref={bucketAnalyticsCustomFormularef} style={{position: 'relative'}}>
              <div className={classes.datechartHeader}>
                  <h2 className={classes.chartTitleFullWidth}>{t('XdataAnalyticsDetail.SalesofCustomFormulaevsRegular')}</h2> 
                  <Tooltip title={t('XdataAnalyticsDetail.reload')}>
                    <ChartButton style={{padding: 8}} onClick={customFormulaTryClick}>
                      <AiOutlineReload className={classes.DownloadIcon}/>
                    </ChartButton>            
                  </Tooltip>              
              </div>
              
              {
                
                ccCustomFormulaSalesLoading ? 
              <div className={classes.loaderDiv}>
                  <CircularProgress color="secondary"  sx={{color:'#C8102E'}}/>
              </div>:
              ccCustomFormulaSalesError === 0 ? 
              <ErrorBlock btnClick={customFormulaTryClick} />
                :
            customFormulaTotal === 0 ?
            <>
              <div className={classes.loaderDiv}>
              <p className={classes.nodataText}>{t('XdataAnalyticsDetail.Nodatafound')}</p>
            </div>
            <div style={{position: 'absolute', display: 'flex', bottom: 20}}>
            <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
            <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.LastThirtydays')}</p>
          </div>
          </>
            :
              <>
              <PieChart chartInfo={{
                    tooltip: {
                      trigger: 'item'
                    },
                    legend: {
                      bottom: 0,
                      right: 0,
                      align: 'left'
                    },
                    title: {
                      text: t('xdataLocationDetails.total') + customFormulaTotal,
                      left: 'center',
                      top: 'center',
                  },
                  textStyle: {
                    fontFamily: 'Roboto',
                },
                    series: [
                        {
                          stillShowZeroSum: false,
                            name: '',
                            type: 'pie',
                            radius: ['45%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                            show: false,
                            position: 'center'
                            },
                            emphasis: {
                            label: {
                                show: false,
                                fontSize: '18',
                                fontWeight: 'bold',
                                // formatter: () => {
                                //   return "Total:" + customFormulaTotal; // Use sum variable here
                                // },
                            }
                            },
                            labelLine: {
                            show: false
                            },
                            data: [
                                { 
                                    value: customFormulaSalesRegular, 
                                    name: t('xdataLocationDetails.regular'), 
                                    itemStyle: { color: '#C8102E' },
                                    selected: true
                                },
                                { 
                                    value: customFormulaSalesCustom, 
                                    name: t('xdataLocationDetails.custom'), 
                                    itemStyle:{ color: '#36333d' }
                                }
                            ]
                          }
                    ]
                  }}/>
                  <div style={{marginTop: '5px', display: 'flex'}}>
                  <BiInfoCircle style={{fontSize: 14, marginRight: 5}}/>
                  <p style={{fontFamily: "Roboto", fontSize: 11}}>{t('XdataAnalyticsDetail.last365Days')}</p>
                </div>
                </>
                }
          </div>
        </div>

      </DashboardLayout>
      
    )
  }
}
}
