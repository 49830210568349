import React,{useState,useMemo, useEffect} from 'react'
import { useNavigate ,useLocation} from "react-router-dom";
import { styled } from '@mui/system';
import { Button,SvgIcon } from '@mui/material';
import { ReactComponent as ArrowDownIcon} from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon} from "assets/icons/arrow-up.svg";

const Root = styled('div')(({ theme }) => ({
    cursor:"pointer",
    marginTop:20,
    width:"100%",
    height:"auto",
    display:"flex",
    flexDirection:"column",
    // alignItems:"center",
    // justifyContent:"space-between",
    color:"#FFFFFF",
    '& .label':{
        display:"flex",
        alignItems:"center",
        fontSize: 15
    },
    '& .left-icon':{
        marginRight:20,
    },
    '& .right-icon':{
        
    },
    '& .active':{
        color:"#36333D",
        backgroundColor:"#FFDFE5",
    },
    [theme.breakpoints.down('xl')]: {
        marginTop:23,
    }
}));

const ParentMenu = styled(Button)(({ theme,isactive }) => ({
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    height:"30px !important",
    minHeight:"30px !important",
    padding:"11px 16px",
    fontSize:18,
    fontFamily:"Roboto",
    fontWeight:400,
    marginTop: "0px !important",
    // backgroundColor:isactive?"#FFFFFF":"unset",
    backgroundColor:isactive?"#FFDFE5":"unset",
    // color:!isactive?"#FFFFFF":"#36333D",
    color:!isactive?"#36333D":"#C8102E",
    '& .label':{
        fontSize: 18
    },
    "&:hover":{
        backgroundColor:isactive?"#FFDFE5 !important":"unset !important"
    },
    '& .left-icon':{
        fontSize: 21,
        marginRight:20,
        "& >svg>path":{
            // fill:isactive?"#C8102E":"#fff"
            fill:isactive?"#C8102E":"#36333D"
        }
    },
   [theme.breakpoints.down('xl')]: {
        fontSize:15,
        height:"42px !important",
        minHeight:"42px !important",
        padding:"6px 11px",
        '& .label':{
            fontSize: 13
        },
   },
   [theme.breakpoints.up('xl')]: {
    fontSize:"18px !important",
    height:"65px !important",
    minHeight:"65px !important",
    padding:"6px 11px",
    '& .label':{
        fontSize: 21
    },
}
}));

const ChildMenu = styled(Button)(({ theme,leftmargin ,isactive}) => ({
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    height:"35px !important",
    minHeight:"35px !important",
    padding:"9px 19px  !important",
    marginTop:13,
    minWidth: "0px !important",
    marginLeft:leftmargin,
    // backgroundColor:isactive?"#FFFFFF":"unset",
    backgroundColor:isactive?"#FFDFE5":"unset",
    // color:!isactive?"#FFFFFF":"#36333D",
    color:!isactive?"#36333D":"#C8102E",
    "&:hover":{
        // backgroundColor:isactive?"#FFFFFF !important":"unset !important"
        backgroundColor:isactive?"#FFDFE5 !important":"unset !important"
    },
    '& .label':{
        fontSize: 16
    },
    [theme.breakpoints.down('xl')]: {
        fontSize:12,
        height:"30px !important",
        minHeight:"30px !important",
        padding:"4px 14px  !important",
        '& .label':{
            fontSize: 12
        },
   },
   [theme.breakpoints.up('xl')]: {
    fontSize:12,
    height:"40px !important",
    minHeight:"40px !important",
    padding:"4px 14px  !important",
    '& .label':{
        fontSize: 18
    },
}
}));

function Menu({data, external_link, userPermission}){
    const [currentMenu,setCurrentMenu] = useState("")
    
    let navigate = useNavigate();
    const { pathname }= useLocation()

    const handleArrowClick = (e, type)=>{ 
        e.stopPropagation();

        if(external_link){
                window.open(""+data.link+"",'_blank');
        }else{
            localStorage.setItem("menu", data?.link)
            if(currentMenu === data.link){
                setCurrentMenu("")
            }
            else{
                if(!data?.subSection){
                    navigate(data.link)
                }
                setCurrentMenu(data.link)
            }
        }
    }

    const leftMargin = data?.level===1?45:(data?.level)*30

    const menuisActive = useMemo(()=> {
        return data?.link === pathname
    },[pathname,data.link])

    
    useEffect(() => {
        if(data?.subSection){
            for(var i = 0; i < data?.subSection.length; i++){
                if(data?.subSection[i].link === pathname){
                    setCurrentMenu(data?.link)
                }
            }
        }
        else{
            setCurrentMenu("")
        }

    }, [pathname])

    return(
        <React.Fragment>
            {
                data?.parent 
                    ?
                    <ChildMenu onClick={(e) => handleArrowClick(e, "child")} id={data?.name} leftmargin={leftMargin} isactive={data.name === "x-data-analytics" ? false : menuisActive}>
                        <span className='label'>
                            {data?.displayName}
                        </span>
                        {data.subSection ?
                            currentMenu === data.link ?
                                <ArrowUpIcon className='right-icon' />
                                :
                                <ArrowDownIcon className='right-icon'/>
                            :
                            undefined
                        }
                    </ChildMenu>
                    :
                    <ParentMenu onClick={(e) => handleArrowClick(e, "parent")} id={data?.name} isactive={menuisActive}>
                        <span className='label'>
                            <SvgIcon className='left-icon' >
                                {data?.icon}
                            </SvgIcon>
                            {data?.displayName}
                        </span>
                        {data.subSection ?
                            currentMenu === data.link ?
                                <ArrowUpIcon className='right-icon' />
                                :
                                <ArrowDownIcon className='right-icon'/>
                            :
                            undefined
                        }
                    </ParentMenu>
            }
            {currentMenu === data.link && data?.subSection && (data?.subSection).map((item,index) => {
                return(
                    <Menu key={index} data={item} />
                )
            })}
        </React.Fragment>
    )
}

export default function CustomDropDown({data, external_link, userPermission}) {
        
  return (
    <Root>
        <Menu data={data} external_link={external_link} userPermission={userPermission}/>
    </Root>
  )
}
