const styles = (theme) => ({
    container:{
        display:"flex",
        alignItems:"center",
        // color:"#36333D",
        color:"#FFFFFF",
        marginLeft:37,
        [theme.breakpoints.down("xl")]:{
            marginLeft:25
         }
    },
    date:{
        fontFamily:"Roboto",
        fontSize:15,
        fontWeight:400,
        marginLeft:14,
        [theme.breakpoints.down("xl")]:{
            fontSize:13,
            marginLeft:7
         },
         [theme.breakpoints.up("xl")]:{
            fontSize:18,
            marginLeft:7
         }
    }
})

export default styles