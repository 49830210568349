import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import {    
    AppBar,
    Box
} from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { CustomSelect,DatePicker } from 'elements';
import UserInfo from './UserInfo';
import styles from "./styles";
import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';


// const SearchBar = styled(InputBase)(({ theme }) => ({
//     fontFamily:"Montserrat-Light",
//     fontSize:15,
//     fontWeight:300,
//     width: "100%",
//     height: "100%",
//     maxHeight:59,
//     borderRadius: 15,
//     backgroundColor:"#F3F3F4",
//     padding:"20px",
//     color:"#36333D",
//     "& .search-icon":{
//         color:"#C8102E",
//         width:20,
//         height:20
//     },
//     [theme.breakpoints.down("xl")]:{
//         padding:15,
//         fontSize:13,
//         fontWeight:300,
//         maxHeight:45,
//     },
// }));

const useStyles = makeStyles(styles)

export default function AppHeader({pageLoading}) {
    const classes = useStyles()
    const [userName, setUserName] = useState(null)
    const [userEmail, setUserEmail] = useState(null)
    const [isImpersonate, setIsImpersonate] = useState(false)
    const { user } = useSelector((state) => state.auth.login);
    const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
    const { language } = useSelector((state) => state.language);
    const theme = useTheme()

    const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => { 
        if(user?.name){
            setUserName(user.name)
        }
        else{
            setUserName('')
        }
        if(user?.email){
            setUserEmail(user.email)
        }
    },[user]);

    useEffect(() => { 
        if(impersonateUserInfo?.status === "success"){
            setIsImpersonate(true)
            let impersonateUserInfoData = impersonateUserInfo?.data          
            if(impersonateUserInfoData?.first_name){
                setUserName(impersonateUserInfoData.first_name)
            }
            else{
                setUserName('')
            }
            if(impersonateUserInfoData?.email){
                setUserEmail(impersonateUserInfoData.email)
            }
        }
    },[impersonateUserInfo]);

  return (
    <>
        {pageLoading&&<div style={{width:'100%', height:'100%', backgroundColor:'#000',opacity:'0.6',position:'fixed',zIndex:1400}}>
            <Box sx={{ textAlign: 'center',marginTop: '20%' }}>
                <CircularProgress sx={{color:'#fff'}}/>
            </Box>
        </div>}
        <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: '#C8102E'}}>        
            <div  style={{display: 'flex'}}>            
                <Box className={classes.centerContainer}>
                    
                    <CustomSelect 
                        label={language.languageLabel}
                        options={[{label : "English", code :"en"}, {label : "italiano", code :"it"}, {label : "简体中文", code :"cn"}]}
                    />
                    
                </Box>
                <Box className={classes.rightContainer}>
                    
                    <DatePicker />
                    {/* <div className={classes.chipRoot}>
                        <NotificationsNoneOutlinedIcon style={isSmallerDesktop ? null : {width: 30, height: 30}}/>
                    </div> */}
                    <UserInfo 
                        email={userEmail}
                        name={userName}
                        isImpersonate={isImpersonate}
                    />
                </Box>
            </div>
        </AppBar>
    </>
  )
}
